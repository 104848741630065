import UdnoSvg from "../../../Assets/Svg/undo";
import SmsSvg from "../../../Assets/Svg/login/smsSvg";
import { NavLink } from "react-router-dom";

const ForgetPage = () => {
  return (
    <div className="max-w-[400px] mt-20 mx-auto p-6">
      <div className="flex w-full justify-between opacity-50">
        <NavLink to="/login-signup">
          {" "}
          <UdnoSvg />
        </NavLink>
        <h1 className="text-lg font-semibold">فراموشی رمز عبور</h1>
      </div>{" "}
      <div className="flex justify-center">
        <SmsSvg />
      </div>
      <div>
        <h1 className="text-[12px] font-medium opacity-50 text-right mt-8">
          کد تایید{" "}
        </h1>
        <input
          className="bg-[#959595]/10 rounded-[5px] pl-3 w-full h-[40px] text-[18px] font-medium text-black/50 mt-1"
          placeholder="09123456789"
        />
        <p className="text-[12px] text-black/50 text-right font-semibold mt-6">
          <span className="mr-1">30</span> ارسال مجدد{" "}
        </p>

        <button className="bg-black rounded-[15px] text-white text-center h-[70px] w-full mt-8 text-[18px]">
          ورود
        </button>
      </div>
    </div>
  );
};

export default ForgetPage;
