import { useState } from "react";
import EthLogo from "../../../Assets/Png/eth.png";
import TrxLogo from "../../../Assets/Png/tron.png";
import BtcLogo from "../../../Assets/Png/btc.png";
import UsdtLogo from "../../../Assets/Png/usdt.png";

const TradeBackground = () => {
  const [activeLogo, setActiveLogo] = useState(null);
  console.log(activeLogo);

  const handleMouseEnter = (logo) => {
    setActiveLogo(logo);
  };

  const handleMouseLeave = () => {
    setActiveLogo(null);
  };

  return (
    <div className="fixed bg-white h-full w-full">
      {/* UsdtLogo */}{" "}
      <div className="absolute w-20 h-20 -top-[50px] left-[10%]">
        <div
          onMouseEnter={() => handleMouseEnter("usdt")}
          onMouseLeave={handleMouseLeave}
          className={`transition-all duration-300 ${
            activeLogo !== "usdt" ? "blur-[3px]" : ""
          }`}
        >
          <img src={UsdtLogo} alt="ETH Logo" />
        </div>
      </div>
      {/* BtcLogo */}
      <div className="absolute w-24 h-24 top-[30px] left-[%]">
        <div
          onMouseEnter={() => handleMouseEnter("btc")}
          onMouseLeave={handleMouseLeave}
          className={`transition-all duration-300 ${
            activeLogo !== "btc" ? "blur-[3px]" : ""
          }`}
        >
          <img src={BtcLogo} alt="BTC Logo" />
        </div>
      </div>
      {/* EthLogo */}
      <div className="absolute w-20 h-20 left-[80%] top-[30px]">
        <div
          onMouseEnter={() => handleMouseEnter("eth")}
          onMouseLeave={handleMouseLeave}
          className={`transition-all duration-300 ${
            activeLogo !== "eth" ? "blur-[3px]" : ""
          }`}
        >
          <img src={EthLogo} alt="ETH Logo" />
        </div>
      </div>
      {/* TrxLogo */}
      <div className="absolute w-20 h-20 -top-[60px] left-[70%]">
        <div
          onMouseEnter={() => handleMouseEnter("trx")}
          onMouseLeave={handleMouseLeave}
          className={`transition-all duration-300 ${
            activeLogo !== "trx" ? "blur-[3px]" : ""
          }`}
        >
          <img src={TrxLogo} alt="TRX Logo" />
        </div>
      </div>
      {/* Add more logo components and their handlers as needed */}
    </div>
  );
};

export default TradeBackground;
