import { NavLink } from "react-router-dom";
import ActiveSupportSVG from "../../Assets/Svg/Active Support";
import UdnoSvg from "../../Assets/Svg/undo";

function Support() {
  return (
    <div className="max-w-[400px] mx-auto">
      <div className="flex justify-between mt-[40px]">
        <span className="ml-4">
          <UdnoSvg />
        </span>
        <p className="mr-5 text-[20px] opacity-50 font-semibold">پشتیبانی</p>
      </div>
      <div className="w-[300px] mx-auto">
        <ActiveSupportSVG />
      </div>
      <div className="flex justify-between w-full mt-[40px]">
        <NavLink to={"/newticket"}>
          <div className="w-[200px] h-[55px] bg-black rounded-[15px] ml-[20px] cursor-pointer">
            <p className="text-white text-center pt-[15px]">ارسال تیکت جدید</p>
          </div>
        </NavLink>
        <p className="text-sm font-semibold text-right mr-[35px] mt-4">
          تیکت های من
        </p>
      </div>

      <section className="w-[350px] h-full border-2 rounded-t-[20px] mx-auto mt-10">
        <div className="flex w-full justify-between text-[15px] font-medium px-10 py-4">
          <p>وضعیت</p>
          <p>موضوع</p>
        </div>
        <hr />
        <div className="flex-col text-[12px] font-semibold mt-6">
          {/* MAP HERE */}
          <div className="flex justify-between w-full px-6 pb-8 border-b border-black/30 hover:border-black">
            {" "}
            <div className="w-[100px] h-[30px] bg-gray-300 rounded-[10px]">
              <p className="text-center pt-1"> منتظر پاسخ</p>
            </div>
            <p className="pt-1">دیرکرد واریز</p>
          </div>
          <div className="flex justify-between w-full px-6 pb-8 mt-7 border-b border-black/30 hover:border-black">
            {" "}
            <div className="w-[100px] h-[30px] bg-gray-300 rounded-[10px]">
              <p className="text-center pt-1"> پاسخ داده شده</p>
            </div>
            <p className="pt-1">واریز</p>
          </div>
          <div className="flex justify-between w-full px-6 pb-8 border-b mt-7 border-black/30 hover:border-black">
            {" "}
            <div className="w-[100px] h-[30px] bg-gray-300 rounded-[10px]">
              <p className="text-center pt-1"> بسته شده</p>
            </div>
            <p className="pt-1">برداشت</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Support;
