import { useParams } from "react-router-dom";
import { useEffect } from "react";
import TradingViewWidget from "../../Widgets/TradingViewWidget";
import UdnoSvg from "../../Assets/Svg/undo";
import {
  CompanyProfile,
  SymbolInfo,
  TechnicalAnalysis,
} from "react-ts-tradingview-widgets";

const Coin = () => {
  const { coinId } = useParams();
  console.log(coinId);

  useEffect(() => {
    // Perform data fetching based on coinId
  }, [coinId]);

  return (
    <div className="mt-[100px] mx-auto max-w-[900px]">
      {/* head */}
      <div className="px-3">
        <div className="flex items-center">
          <h1 className="text-[28px] text-black/50 ">BTC</h1>
          <h1 className="ml-2 text-[28px]">بیت کوین</h1>
        </div>

        <div className="flex align-text-bottom">
          {" "}
          <div className="text-[35px] font-bold">450,000,000,000</div>{" "}
          <div className="flex mt-3 ml-1 ">
            <span className=" text-[13px] ">تومان</span>{" "}
            <div className="flex"></div>
          </div>
        </div>
      </div>

      <SymbolInfo
        colorTheme="light"
        symbol="BTCUSDT"
        isTransparent="true"
        autosize
      ></SymbolInfo>
      
      {/* price */}
      <div className="text-center">
        <div className="h-[16px] w-[200px] right-0 left-0 ml-auto mr-auto  absolute -mt-6 bg-white"></div>
        <div className="">
          {" "}
          <TradingViewWidget symbol={"BTC"} />
        </div>
      </div>
      <h1 className="text-center mt-[60px] text-[25px] font-bold">
        شاخص ترس و طمع
      </h1>
      <div className="h-[600px] ">
        <TechnicalAnalysis
          isTransparent="true"
          colorTheme="white"
          width="100%"
          symbol="BTC"
          interval="4h"
          autosize="true"
        ></TechnicalAnalysis>
      </div>

      {/* button */}
      <div className="fixed shadow-xl bottom-20 left-[50%] ml-[-184px] rounded-[20px] border flex align-middle px-8 bg-gray-50">
        {" "}
        <div className="mb-4   w-[300px] h-[50px] rounded-full bg-black text-white mx-auto mt-4 flex items-center justify-center cursor-pointer">
          <p className="text-[18px]">معامله</p>
        </div>
      </div>
    </div>
  );
};

export default Coin;
