const AlmostEqual = ({color}) => {
  return (
    <svg
      width="7"
      height="5"
      viewBox="0 0 7 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-3 h-3"
    >
      <path
        d="M3.50007 4.20726C2.3908 3.83695 1.69493 3.81178 1.27823 4.1274C0.817334 4.47552 0.714355 4.43522 0.714355 3.9064C0.714355 2.92407 1.62812 2.71774 3.64627 3.24424C4.95514 3.58577 5.15608 3.60118 5.57371 3.39244C5.83018 3.2643 6.09529 3.13253 6.16289 3.09957C6.23049 3.06707 6.28578 3.26513 6.28578 3.54073C6.28578 3.9299 6.17779 4.09769 5.80232 4.29176C5.19221 4.60729 4.63715 4.58695 3.50007 4.20726ZM3.50007 1.74933C2.3908 1.37892 1.69493 1.35376 1.27823 1.66855C0.817334 2.0175 0.714355 1.9773 0.714355 1.44838C0.714355 0.491303 1.75055 0.230282 3.47277 0.753346C4.82231 1.16331 5.29156 1.1855 5.72191 0.860039C6.18517 0.509596 6.28578 0.551846 6.28578 1.09645C6.28578 1.47039 6.17329 1.64199 5.80232 1.83383C5.19221 2.14927 4.63715 2.12893 3.50007 1.74933Z"
        fill={color}
        fill-opacity="0.5"
      />
    </svg>
  );
};

export default AlmostEqual;
