import { Route, Routes } from "react-router-dom";
import "./App.css";
import Trade from "./Pages/Trade/Index";
import Support from "./Pages/Support";
import Profile from "./Pages/Profile/profile";
import Wallet from "./Pages/Wallet/Index";
import Authenticate from "./Pages/Profile/Sections/Authenticate";
import BankCards from "./Pages/Profile/Sections/Bank-cards";
import History from "./Pages/Profile/Sections/History";
import Referral from "./Pages/Profile/Sections/Referral/Referral";
import Statics from "./Pages/Profile/Sections/Statics/Index";
import User from "./Pages/Profile/Sections/User";
import Page404 from "./Pages/NotFound/Index";
import NewTicket from "./Pages/Support/NewTicket";
import LoginSignup from "./Pages/Login-signup/Index";
import Login from "./Pages/Login-signup/login/Index";
import Signup from "./Pages/Login-signup/signup/Index";
import ForgetPage from "./Pages/Login-signup/forget/Index";
import CoinsList from "./Pages/CoinsList/Index";
import Coin from "./Pages/CoinInfo/Index";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<Page404 />} />
      <Route path="/" element={<Trade />} />
      {/* footer */}
      <Route path="/profile" element={<Profile />} />
      <Route path="/support" element={<Support />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/coins" element={<CoinsList />} />
      {/* Login */}
      <Route path="/login-signup" element={<LoginSignup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forget" element={<ForgetPage />} />
      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="/bankCards" element={<BankCards />} />
      <Route path="/history" element={<History />} />
      <Route path="/referral" element={<Referral />} />
      <Route path="/statics" element={<Statics />} />
      <Route path="/user" element={<User />} />
      <Route path="/newticket" element={<NewTicket />} />
      <Route path="/coinslist/:coinId" element={<Coin />} />
    </Routes>
  );
}

export default App;
