import React from "react";
import { NavLink } from "react-router-dom";
import Coins from "../../API/Coins.json";
import UdnoSvg from "../../Assets/Svg/undo";

const CoinsList = () => {
  return (
    <div className="max-w mt-[70px]">
      <h1 className="text-center text-[30px] font-bold">رمز ارزها</h1>
      {/* List Head */}{" "}
      <div className="text-black/50 max-w-[390px] px-10 border py-3 shadow-xl rounded-t-[10px] bg-white mx-auto text-15 font-medium flex justify-between mt-4">
        <p className="">قیمت</p>
        <p className="">نام ارز</p>
      </div>
      <div className="max-w-[390px] max-h-[700px] overflow-y-auto rounded-b-[10px] border shadow-2xl mx-auto no-scrollbar h-[calc(100vh-255px)]">
        {/* List Body */}
        <div className="flex flex-col gap-y-7 mt-5">
          {/* Map */}
          {Coins.currencies.map((currency, index) => (
            <NavLink key={index} to={`/coinslist/${currency.symbol}`}>
              <div className="h-[60px] w-[315px] flex mx-auto cursor-pointer">
                {/* Price */}
                <div className="w-[50%]">
                  <div className="flex">
                    <p className="text-[14px] font-semibold">
                      {currency.toman_price}
                    </p>
                    <span className="text-[10px] font-medium text-black/50 pt-[2px] ml-1">
                      تومان
                    </span>
                  </div>
                  <p className="text-[12px] mt-1">${currency.usd_price}</p>
                  <div className="flex">
                    <p className="text-[14px] text-black/50 mt-[2px]">
                      %{currency.price_change}
                    </p>
                    <div className="-rotate-90 text-red-500">
                      <UdnoSvg />
                    </div>
                  </div>
                </div>
                {/* Currency name */}
                <div className="w-[50%] text-right flex items-end justify-end pb-3">
                  <div className="font-semibold mr-1">
                    <p className="text-[15px]">{currency.name_fa}</p>
                    <p className="text-[12px] text-black/50">
                      {currency.symbol}
                    </p>
                  </div>
                  <img
                    className="w-[35px] h-[35px]"
                    src={currency.image}
                    alt={currency.symbol}
                  />
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoinsList;
