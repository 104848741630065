import FooterCoins from "../../../Assets/Icons/FooterCoins.svg";
import FooterTrade from "../../../Assets/Icons/FooterTrade.svg";
import FooterWallet from "../../../Assets/Icons/FooterWallet.svg";
import FooterProfile from "../../../Assets/Icons/FooterProfile.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="fixed bottom-3 left-0 right-0 mx-auto px-2">
      <div className="flex justify-center bg-white border-top border-2 rounded-2xl p-2 text-[#808080] shadow-2xl max-w-[400px] mx-auto">
        <div className="flex justify-around items-center w-full">
          <NavLink to="/">
            <div className="text-center">
              <img className="mx-auto" src={FooterTrade} alt="coins" />
              <p className="text-xs font-semibold mt-1">معامله</p>
            </div>
          </NavLink>

          <NavLink to="/coins">
            <div className="text-center">
              <img className="mx-auto" src={FooterCoins} alt="coins" />
              <p className="text-xs font-semibold mt-1">ارزها</p>
            </div>
          </NavLink>

          <NavLink to="/wallet">
            <div className="text-center">
              <img className="mx-auto" src={FooterWallet} alt="coins" />
              <p className="text-xs font-semibold mt-1">کیف پول</p>
            </div>
          </NavLink>

          <NavLink to="/profile">
            <div className="text-center">
              <img className="mx-auto" src={FooterProfile} alt="coins" />
              <p className="text-xs font-semibold mt-1">پروفایل</p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
