import { NavLink } from "react-router-dom";
import UdnoSvg from "../../../../Assets/Svg/undo";
import TimeSVG from "../../../../Assets/Svg/time";
import CardTransfer from "../../../../Assets/Svg/card-transfer";
import TradeSvg from "../../../../Assets/Svg/tradeSvg";
import TransferSvg from "../../../../Assets/Svg/transferSvg";

const History = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[400px] flex flex-col mt-6 mr-6 w-full px-3">
        <div className="w-full items-end flex justify-between ">
          <div className="flex w-full items-center ml-auto cursor-pointer justify-between">
            {" "}
            <NavLink to={"/profile"}>
              {" "}
              <UdnoSvg />
            </NavLink>
            <div className="flex flex-row mr-[-10px]">
              {" "}
              <div className="">
                <p className="text-right text-[14px] font-medium mt-1">
                  تاریخچه تراکنش ها
                </p>
                <p className="text-[12px] opacity-50  text-right">
                  رمز ارزها و تومان
                </p>
              </div>{" "}
              <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                <TimeSVG />
              </div>
            </div>
          </div>
        </div>
        {/* options */}
        <div className="flex flex-col items-end mt-[100px] gap-y-8">
          <div>
            {" "}
            <NavLink to={"/history/fiat"}>
              <div className="flex flex-row mr-[-10px]">
                {" "}
                <div className="">
                  <p className="text-right text-[14px] font-medium mt-1">
                    کیف پول تومانی
                  </p>
                  <p className="text-[12px] opacity-50  text-right">
                    واریز و برداشت های تومان{" "}
                  </p>
                </div>{" "}
                <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                  <CardTransfer />
                </div>
              </div>
            </NavLink>
          </div>
          <NavLink to={"/history/crypto"}>
            <div className="flex flex-row mr-[-10px]">
              {" "}
              <div className="">
                <p className="text-right text-[14px] font-medium mt-1">
                  رمز ارزها{" "}
                </p>
                <p className="text-[12px] opacity-50  text-right">
                  مبادلات رمز ارز{" "}
                </p>
              </div>{" "}
              <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                <TradeSvg />
              </div>
            </div>
          </NavLink>
          <NavLink to={"/history/blockchain"}>
            <div className="flex flex-row mr-[-10px]">
              {" "}
              <div className="">
                <p className="text-right text-[14px] font-medium mt-1">
                  انتقالات{" "}
                </p>
                <p className="text-[12px] opacity-50  text-right">
                  واریز و برداشت ها در بلاکچین{" "}
                </p>
              </div>{" "}
              <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                <TransferSvg />
              </div>
            </div>{" "}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default History;
