import UserSVG from "../../Assets/Svg/user";
import TimeSVG from "../../Assets/Svg/time";
import PieChartSVG from "../../Assets/Svg/pieChart";
import CardSVG from "../../Assets/Svg/card";
import Friends from "../../Assets/Svg/friends";
import AuthSVG from "../../Assets/Svg/auth";
import { NavLink } from "react-router-dom";
import settings from "../../Assets/Images/Settings.png";

const Profile = () => {
  return (
    <div>
      <p className="font-semibold text-[20px] text-black/50 text-right mr-8 my-2 md:text-center">
        پروفایل و تنظیمات
      </p>
      {/* svg */}
      

      {/* list */}
      <div className="mt-12 w-[310px] mx-auto flex flex-col gap-y-8">
        {/* user */}
        <NavLink to="/user" className="flex">
          <div className="flex ml-auto cursor-pointer border w-full justify-end px-2 py-2 rounded-[40px] shadow-2xl">
            <div className="">
              <p className="text-right text-[14px] font-medium">حساب کاربری</p>
              <p className="text-[12px] opacity-50">تنظیمات امنیتی</p>{" "}
            </div>
            <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
              <UserSVG />
            </div>
          </div>
        </NavLink>
        {/* history */}
        <NavLink to="/history" className="flex">
          <div className="flex ml-auto  w-full  cursor-pointer border justify-end px-2 py-2 rounded-[40px] shadow-2xl">
            <div className="">
              <p className="text-right text-[14px] font-medium">
                {" "}
                تاریخچه تراکنش ها
              </p>
              <p className="text-[12px] opacity-50 text-right">
                رمز ارز و تومان
              </p>
            </div>
            <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
              <TimeSVG />
            </div>
          </div>{" "}
        </NavLink>
        {/* Chart */}
        <NavLink to="/statics" className="flex">
          <div className="flex ml-auto  w-full cursor-pointer border justify-end px-2 py-2 rounded-[40px] shadow-2xl">
            <div>
              <p className="text-right text-[14px] font-medium">آمار</p>
              <p className="text-[12px] opacity-50">میزان فعالیت شما</p>{" "}
            </div>
            <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
              <PieChartSVG />
            </div>
          </div>
        </NavLink>
        {/* Card */}
        <NavLink to="/bankCards" className="flex">
          <div className="flex ml-auto  w-full cursor-pointer border justify-end px-2 py-2 rounded-[40px] shadow-2xl">
            <div>
              <p className="text-right text-[14px] font-medium">
                کارت های بانکی
              </p>
              <p className="text-[12px] opacity-50"> حساب های ثبت شده</p>{" "}
            </div>
            <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
              <CardSVG />
            </div>
          </div>{" "}
        </NavLink>
        {/* Friends */}
        <NavLink to="/referral" className="flex">
          <div className="flex ml-auto  w-full cursor-pointer border justify-end px-2 py-2 rounded-[40px] shadow-2xl">
            <div>
              <p className="text-right text-[14px] font-medium">
                دعوت از دوستان{" "}
              </p>
              <p className="text-[12px] opacity-50">
                {" "}
                دریافت هدیه با دعوت دوستان
              </p>{" "}
            </div>
            <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
              <Friends />
            </div>
          </div>
        </NavLink>
        {/* Auth */}
        <NavLink to="/authenticate" className="flex">
          <div className="flex ml-auto  w-full cursor-pointer border justify-end px-2 py-2 rounded-[40px] shadow-2xl">
            <div>
              <p className="text-right text-[14px] font-medium"> احرازهویت</p>
              <p className="text-[12px] opacity-50">
                {" "}
                فعال سازی خرید با تومان
              </p>{" "}
            </div>
            <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full">
              <AuthSVG />
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Profile;
