import { NavLink } from "react-router-dom";
import UdnoSvg from "../../../../Assets/Svg/undo";
import Friends from "../../../../Assets/Svg/friends";
import TelegramSvg from "../../../../Assets/Svg/telegram";
import TickSvg from "../../../../Assets/Svg/TickSvg";

const Referral = () => {
  return (
    <div>
      <div className="w-full flex justify-center">
        <div className="max-w-[400px] flex flex-col mt-6 mr-6 w-full px-3">
          <div className="w-full items-end flex justify-between ">
            {" "}
            {/* head */}
            <div className="flex w-full items-center ml-auto cursor-pointer justify-between">
              {" "}
              <NavLink to={"/profile"}>
                {" "}
                <UdnoSvg />
              </NavLink>
              <div className="flex flex-row mr-[-10px]">
                {" "}
                <div className="">
                  <p className="text-right text-[14px] font-medium mt-1">
                    دعوت از دوستان{" "}
                  </p>
                  <p className="text-[12px] opacity-50  text-right">
                    دریافت هدیه با دعوت دوستان{" "}
                  </p>
                </div>{" "}
                <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                  <Friends />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>{" "}
      {/* balance */}
      <div className=" mt-5 pb-1 shadow-2xl">
        <p className="text-[12px] text-center mt-2 font-semibold ml-40">
          امتیاز شما
        </p>
        <p className="text-[40px] font-bold text-center ">926,000,000</p>
      </div>
      {/* prize box */}
      <p className="text-center my-5 text-xl font-semibold">کمپین ها</p>
      <div className="w-[300px] h-[300px] border mx-auto mt-3 rounded-[15px] shadow-2xl">
        <p className="text-[20px]  font-bold text-center mt-3 shadow-md pb-3 ">
          ایردراپ تلگرام پرمیوم 3 ماهه{" "}
        </p>
        <div className="mx-auto w-full flex justify-center mt-3  shadow-xl pb-3">
          <TelegramSvg className="w-[20]" />
        </div>
        <div className="flex justify-center text-[14px] pt-6">
          <div className="mr-[1px]">
            <TickSvg />
          </div>
          <p className="mt-[1px]">شما در این قرعه شرکت داده خواهید شد</p>
        </div>
      </div>
      {/* details */}
      <div className="border shadow-2xl mx-2 mt-3">
        {" "}
        <p className="text-right text-[12px] mr-5 ml-10">
          {" "}
          <br />
          خرید 1 دلار توسط شما یا شخص معرفی شده 100 امتیاز -
          <br />
          احراز هویت شما یا شخص معرفی شده 500 امتیاز - <br />
          عضویت دوستان با لینک دعوت شما 50 امتیاز -
          <br/>
          <br /> با بالاتر بردن موجودی امتیاز هاتون شانس برنده شدن در قرعه
          کشی و ایردراپ ها رو افزایش بدید
        </p>
        <div className="text-center text-[16px] text-blue-700 mt-5 mb-1">
          gitinext.com/link/ref/1234456788
        </div>
      </div>
    </div>
  );
};

export default Referral;
