import { NavLink } from "react-router-dom";
import QrSVG from "../../../../Assets/Icons/QrSVG";
import LockSVG from "../../../../Assets/Icons/lockSVG";
import UdnoSvg from "../../../../Assets/Svg/undo";
import UserSVG from "../../../../Assets/Svg/user";

const User = () => {
  return (
    <div className="flex justify-center">
      <div className="max-w-[400px] flex flex-col mt-6 mr-6">
        <div className="w-full items-end flex justify-between ">
          <div className="flex w-full items-center ml-auto cursor-pointer justify-between">
            {" "}
            <NavLink to={"/profile"}>
              {" "}
              <UdnoSvg />
            </NavLink>
            <div className="flex flex-row mr-[-10px]">
              {" "}
              <div className="">
                <p className="text-right text-[14px] font-medium mt-1">
                  حساب کاربری
                </p>
                <p className="text-[12px] opacity-50">تنظیمات امنیتی</p>
              </div>{" "}
              <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                <UserSVG />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <p className="text-right  text-[14px] font-medium">تغییر رمز عبور</p>
          <LockSVG />
        </div>
        <p className="text-right text-[14px] font-medium mr-1 mt-4 mb-2">
          رمز عبور جدید
        </p>
        <div className="w-full flex justify-center">
          <input className="w-[300px] h-[40px] border-2 focus:border-black border-black/50 transition duration-500 text-right pr-3 rounded-[10px] text-[12px]" />
        </div>
        <p className="text-right text-[14px] font-medium mr-1 mt-4 mb-2">
          کد تایید
        </p>
        <div className="w-full flex justify-center">
          <input className="w-[300px] h-[40px] border-2 focus:border-black border-black/50 transition duration-500 text-right pr-3 rounded-[10px] text-[12px]" />
        </div>
        <button className="w-[200px] h-[40px] rounded-[5px] mx-auto mt-5 text-white text-[14px] text-center bg-black">
          ارسال کد تایید
        </button>
        {/* 2-Step */}
        <div className="flex justify-end mt-12">
          <p className="text-right mr-2 text-[14px] font-medium">
            ورود دو مرحله ای
          </p>
          <QrSVG />
        </div>
        <div className="w-[150px] h-[50px] bg-[#D9D9D9] rounded-[5px] text-center pt-4 text-[14px] mx-auto mt-5">
          پیامک
        </div>
        <button className="w-[250px] h-[40px] rounded-[5px] mx-auto mt-5 text-white text-[14px] text-center bg-black">
          فعالسازی
        </button>
      </div>
    </div>
  );
};

export default User;
