import { useState, useEffect } from "react";
import TradeBox from "./TradeBox/Index";
import TradeBackground from "./TradeBox/TradeBackground";
import Undo from "../../Assets/Svg/undo";

const Trade = () => {
  const slogans = ["هروقتی", "هرجایی", "به آسونی"];
  const [currentSloganIndex, setCurrentSloganIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSloganIndex((prevIndex) =>
        prevIndex === slogans.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <TradeBackground />
      {/* Box */}{" "}
      <div>
        <div className="max-w-[400px] mx-auto ">
          <div className="text-center mt-[140px] cursor-default max-w-[250px] mx-auto ">
            {slogans.map((slogan, index) => (
              <h1
                key={index}
                className={`text-[35px] drop-shadow-lg absolute top-20 left-20 right-20 mx-auto transition-opacity duration-1000 ${
                  index === currentSloganIndex ? "opacity-100" : "opacity-0"
                }`}
                style={{ zIndex: index === currentSloganIndex ? 10 : -1 }}
              >
                {slogan}
              </h1>
            ))}
            <h1 className="text-[35px] font-bold drop-shadow-lg">معامله کن</h1>
          </div>
          <TradeBox />{" "}
          <div className="text-center mt-12">
            {" "}
            <h1 className="text-[15px] text-black/50 ">
              خرید ، فروش و مبادله آنی ارزهای برتر
            </h1>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Trade;
