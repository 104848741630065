import SupportBtn from "./SupportBtn";
import LogoLight from "../../../Assets/Gifs/logoLightMotin.gif";
import LogoType from "../../../Assets/Images/Giti Next-type.png";

const Header = () => {
  return (
    <div className="w-full flex justify-between fixed left-0 right-0 mx-auto items-center p-4 top-0 bg-white z-10 max-w-[950px] ">
      <div className="flex items-center">
        <img className="w-[60px] h-[35px]" src={LogoLight} alt="logo" />
        <img
          className="w-[120px] h-[40px] ml-[5px] mt-[-3px]"
          src={LogoType}
          alt="logo"
        />
      </div>
      <SupportBtn />
    </div>
  );
};

export default Header;
