const ActiveSupportSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g id="freepik--background-complete--inject-266">
        <rect y="382.4" width="500" height="0.25" fill="#ebebeb"></rect>
        <rect
          x="416.78"
          y="398.49"
          width="33.12"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="322.53"
          y="401.21"
          width="8.69"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="396.59"
          y="389.21"
          width="19.19"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="52.46"
          y="390.89"
          width="43.19"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="104.56"
          y="390.89"
          width="6.33"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="131.47"
          y="395.11"
          width="93.68"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          fill="#ebebeb"
        ></path>
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          fill="#ebebeb"
        ></path>
        <rect
          x="179.13"
          y="88.61"
          width="231.69"
          height="154.07"
          transform="translate(589.96 331.3) rotate(180)"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="175.55"
          y="88.61"
          width="231.69"
          height="154.07"
          transform="translate(582.79 331.3) rotate(180)"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="221.59"
          y="56.24"
          width="139.61"
          height="218.82"
          transform="translate(457.04 -125.75) rotate(90)"
          fill="#fff"
        ></rect>
        <rect
          x="113.54"
          y="164.29"
          width="139.61"
          height="2.71"
          transform="translate(348.99 -17.69) rotate(90)"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="178.21"
          y="192.06"
          width="226.37"
          height="9.71"
          transform="translate(582.79 393.82) rotate(180)"
          fill="#f5f5f5"
        ></rect>
        <g opacity="0.4">
          <rect
            x="168.36"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="190.24"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(391.32 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="201.08"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="222.95"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(456.75 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="233.8"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="255.67"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(522.19 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="266.52"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="288.39"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(587.63 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="299.24"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="321.11"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(653.06 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="331.95"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="353.83"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(718.5 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="364.67"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
          <rect
            x="386.55"
            y="81.01"
            width="10.84"
            height="119.87"
            transform="translate(783.94 281.89) rotate(180)"
            fill="#f5f5f5"
          ></rect>
          <rect
            x="397.39"
            y="81.01"
            width="21.87"
            height="130.34"
            fill="#e6e6e6"
          ></rect>
        </g>
        <rect
          x="82.79"
          y="277"
          width="51.29"
          height="105.4"
          transform="translate(216.87 659.4) rotate(180)"
          fill="#ebebeb"
        ></rect>
        <polygon
          points="92.53 382.4 82.79 382.4 82.79 360.93 102.75 360.93 92.53 382.4"
          fill="#f5f5f5"
        ></polygon>
        <rect
          x="375.43"
          y="277"
          width="51.29"
          height="105.4"
          transform="translate(802.15 659.4) rotate(180)"
          fill="#ebebeb"
        ></rect>
        <rect
          x="82.79"
          y="277"
          width="301.14"
          height="92.23"
          transform="translate(466.72 646.23) rotate(180)"
          fill="#f5f5f5"
        ></rect>
        <path
          d="M373.92,343.21H284.65a.82.82,0,0,1-.82-.82V284a.82.82,0,0,1,.82-.82h16.48a.78.78,0,0,1,.49.16,28.89,28.89,0,0,0,8.05,4.13,33.94,33.94,0,0,0,10.78,1.72h17.66A31.76,31.76,0,0,0,357,283.37a.77.77,0,0,1,.48-.16h16.49a.82.82,0,0,1,.82.82v58.36A.82.82,0,0,1,373.92,343.21Zm-88.45-1.64H373.1V284.85H357.7a33.43,33.43,0,0,1-19.59,6H320.45a35.47,35.47,0,0,1-11.3-1.81,30.59,30.59,0,0,1-8.28-4.2h-15.4Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M278,343.21H188.72a.82.82,0,0,1-.82-.82V284a.82.82,0,0,1,.82-.82h15.59a.78.78,0,0,1,.49.16,28.64,28.64,0,0,0,8,4.13,33.94,33.94,0,0,0,10.78,1.72h17.66a31.67,31.67,0,0,0,18.81-5.85.82.82,0,0,1,.49-.16H278a.81.81,0,0,1,.82.82v58.36A.82.82,0,0,1,278,343.21Zm-88.45-1.64h87.63V284.85H260.86a33.39,33.39,0,0,1-19.57,6H223.63a35.47,35.47,0,0,1-11.3-1.81,30.23,30.23,0,0,1-8.29-4.2h-14.5Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M182.07,343.21H92.8a.82.82,0,0,1-.82-.82V284a.81.81,0,0,1,.82-.82h15.59a.77.77,0,0,1,.48.16,28.89,28.89,0,0,0,8,4.13,34,34,0,0,0,10.79,1.72h17.66a31.75,31.75,0,0,0,18.83-5.85.78.78,0,0,1,.49-.16h17.38a.82.82,0,0,1,.82.82v58.36A.82.82,0,0,1,182.07,343.21Zm-88.45-1.64h87.63V284.85H165a33.42,33.42,0,0,1-19.58,6H127.71a35.38,35.38,0,0,1-11.3-1.81,30.22,30.22,0,0,1-8.29-4.2H93.62Z"
          fill="#e6e6e6"
        ></path>
        <polygon
          points="374.19 382.4 383.93 382.4 383.93 360.93 363.98 360.93 374.19 382.4"
          fill="#f5f5f5"
        ></polygon>
        <path
          d="M143.94,259.34h-8.09c.06-5.6,0-19.82-.57-52.39-1-59-2.87-62.64-2.87-62.64L142,173.45a78.92,78.92,0,0,1,3.94,21.2C147,217.37,143,235.51,143.94,259.34Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M150.09,164.66c10.79-16.33,32.33-24.85,54.35-32.87,8.64-3.15-15.62,35.92-33.26,53.64-13.7,13.76-27,13.45-30.93,27C139.49,196.69,140.79,178.73,150.09,164.66Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M137.73,154.18c-8.46-19.1-29.83-43.87-53.85-61.32-9.43-6.85,13.84,52,30.25,76.06,12.78,18.76,24.7,30.18,26.56,43.56C148.75,195.74,146.18,173.27,137.73,154.18Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M138.53,277h0a17.91,17.91,0,0,0,17.88-16.89L158,243.92H119.06l1.58,16.19A17.92,17.92,0,0,0,138.53,277Z"
          fill="#f0f0f0"
        ></path>
        <polygon
          points="116.54 244.01 160.52 244.01 161.78 238.26 115.28 238.26 116.54 244.01"
          fill="#f0f0f0"
        ></polygon>
        <polygon
          points="118.71 253.87 158.35 253.87 159.49 248.12 117.57 248.12 118.71 253.87"
          fill="#f0f0f0"
        ></polygon>
      </g>
      <g id="freepik--Shadow--inject-266">
        <ellipse
          id="freepik--path--inject-266"
          cx="250"
          cy="416.24"
          rx="193.89"
          ry="11.32"
          fill="#f5f5f5"
        ></ellipse>
      </g>
      <g id="freepik--Table--inject-266">
        <rect
          x="94.71"
          y="318.33"
          width="310.57"
          height="35.16"
          fill="#D9D9D9"
        ></rect>
        <rect
          x="338.67"
          y="318.33"
          width="66.62"
          height="35.16"
          opacity="0.3"
        ></rect>
        <rect
          x="94.71"
          y="353.49"
          width="34.29"
          height="62.75"
          fill="#D9D9D9"
        ></rect>
        <polygon
          points="94.72 353.49 94.72 357 119 364.33 119 416.24 129 416.24 129 353.49 94.72 353.49"
          opacity="0.3"
        ></polygon>
        <rect
          x="371"
          y="353.49"
          width="34.29"
          height="62.75"
          fill="#D9D9D9"
        ></rect>
        <rect
          x="371"
          y="353.49"
          width="34.29"
          height="62.75"
          opacity="0.2"
        ></rect>
        <polygon
          points="371 353.49 371 357 395.29 364.33 395.29 416.24 405.29 416.24 405.29 353.49 371 353.49"
          opacity="0.3"
        ></polygon>
        <rect
          x="304.38"
          y="353.49"
          width="34.29"
          height="62.75"
          fill="#D9D9D9"
        ></rect>
        <polygon
          points="304.38 353.49 304.38 357 328.67 364.33 328.67 416.24 338.67 416.24 338.67 353.49 304.38 353.49"
          opacity="0.3"
        ></polygon>
        <rect
          x="161.33"
          y="353.49"
          width="34.29"
          height="62.75"
          fill="#D9D9D9"
        ></rect>
        <rect
          x="161.33"
          y="353.49"
          width="34.29"
          height="62.75"
          opacity="0.2"
        ></rect>
        <polygon
          points="161.33 353.49 161.33 357 185.62 364.33 185.62 416.24 195.62 416.24 195.62 353.49 161.33 353.49"
          opacity="0.3"
        ></polygon>
      </g>
      <g id="freepik--Character--inject-266">
        <path
          d="M362.48,311.42H172.6a4.28,4.28,0,0,1-4.3-4.6l7.82-124.09a5,5,0,0,1,4.89-4.6H370.88a4.28,4.28,0,0,1,4.3,4.6l-7.82,124.09A5,5,0,0,1,362.48,311.42Z"
          fill="#263238"
        ></path>
        <path
          d="M362.48,311.42H172.6a4.28,4.28,0,0,1-4.3-4.6l7.82-124.09a5,5,0,0,1,4.89-4.6H370.88a4.28,4.28,0,0,1,4.3,4.6l-7.82,124.09A5,5,0,0,1,362.48,311.42Z"
          opacity="0.6"
        ></path>
        <path
          d="M361.06,311.42H171.19a4.28,4.28,0,0,1-4.31-4.6l7.82-124.09a5,5,0,0,1,4.89-4.6H369.46a4.27,4.27,0,0,1,4.3,4.6l-7.82,124.09A5,5,0,0,1,361.06,311.42Z"
          fill="#263238"
        ></path>
        <path
          d="M360.82,313H134.89a5.51,5.51,0,0,0-5,3.09,1.58,1.58,0,0,0,1.45,2.26H364.32a1.58,1.58,0,0,0,1.45-2.26A5.49,5.49,0,0,0,360.82,313Z"
          fill="#263238"
        ></path>
        <path
          d="M360.82,313H328.69a5.48,5.48,0,0,0-4.95,3.09,1.58,1.58,0,0,0,1.44,2.26h39.14a1.58,1.58,0,0,0,1.45-2.26A5.49,5.49,0,0,0,360.82,313Z"
          opacity="0.6"
        ></path>
        <path
          d="M340.9,316.9h-8.3a.73.73,0,0,1-.72-.73h0a.73.73,0,0,1,.72-.73h8.3a.73.73,0,0,1,.73.73h0A.73.73,0,0,1,340.9,316.9Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M234.17,316.9h-8.29a.73.73,0,0,1-.73-.73h0a.73.73,0,0,1,.73-.73h8.29a.73.73,0,0,1,.73.73h0A.73.73,0,0,1,234.17,316.9Z"
          fill="#fff"
          opacity="0.7"
        ></path>
        <rect
          x="343.46"
          y="315.44"
          width="6.63"
          height="1.46"
          rx="0.6"
          fill="#D9D9D9"
        ></rect>
        <path
          d="M171.16,302.34l7.26-115.13a1.93,1.93,0,0,1,1.9-1.78H367.81a1.65,1.65,0,0,1,1.67,1.78l-7.25,115.13a1.94,1.94,0,0,1-1.91,1.78H172.84A1.67,1.67,0,0,1,171.16,302.34Z"
          fill="#fff"
        ></path>
        <path
          d="M277.82,181.88l-62.45,118.7H169.3a1.66,1.66,0,0,1-1.68-1.79l7.26-115.12a1.94,1.94,0,0,1,1.9-1.79Z"
          fill="#D9D9D9"
          opacity="0.1"
        ></path>
        <polygon
          points="332.51 181.88 270.06 300.58 255.5 300.58 317.95 181.88 332.51 181.88"
          fill="#D9D9D9"
          opacity="0.1"
        ></polygon>
        <path
          d="M365.94,183.67l-7.26,115.12a1.93,1.93,0,0,1-1.9,1.79h-71l62.45-118.7h16A1.67,1.67,0,0,1,365.94,183.67Z"
          fill="#D9D9D9"
          opacity="0.1"
        ></path>
        <path
          d="M275.63,182a1.46,1.46,0,0,1-1.43,1.35,1.25,1.25,0,0,1-1.26-1.35,1.45,1.45,0,0,1,1.43-1.35A1.25,1.25,0,0,1,275.63,182Z"
          fill="#fff"
          opacity="0.4"
        ></path>
        <path
          d="M244.65,154.39c-5.47,6.26-8.63,25.45-4.58,34.89s-42.94,18.89-4.17,34.23,69.78-2.45,58.56-18.34,1.21-42.35-11.78-49C270.94,150.11,255.61,141.84,244.65,154.39Z"
          fill="#263238"
        ></path>
        <path
          d="M233.06,231.66c-2.05,7-4.23,14-6.49,21s-4.61,13.92-7.1,20.87l-.95,2.62-.48,1.31-.15.39-.24.59c-.15.38-.38.79-.58,1.18a10.89,10.89,0,0,1-4.21,4.26,11.4,11.4,0,0,1-5.24,1.38,19.34,19.34,0,0,1-7.49-1.47A42.15,42.15,0,0,1,194.5,281a89.57,89.57,0,0,1-17.88-14.46l4.31-5.08c1.45.93,3,1.92,4.5,2.85s3.07,1.83,4.59,2.72a91.15,91.15,0,0,0,9.21,4.71,31.24,31.24,0,0,0,4.44,1.57,8.09,8.09,0,0,0,3.26.38c.28,0,.23-.16,0-.07s-.48.35-.49.32,0,0,0-.06a.13.13,0,0,0,0-.06l.08-.25.38-1.29.77-2.6,6.11-21q3.12-10.5,6.42-21Z"
          fill="#b65b52"
        ></path>
        <path
          d="M180.62,261.3,169,257l4.61,12.28s8.2,1.18,11.38-3.13l-2.59-3.47A4,4,0,0,0,180.62,261.3Z"
          fill="#b55b52"
        ></path>
        <polygon
          points="158.75 260.57 163.17 270.64 173.66 269.26 169.04 256.98 158.75 260.57"
          fill="#b55b52"
        ></polygon>
        <path
          d="M224.58,213.55c-5.32,1.35-9,23.22-9,23.22l14.65,10s14-22.06,9.95-27.9C235.88,212.74,232.33,211.58,224.58,213.55Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M224.58,213.55c-5.32,1.35-9,23.22-9,23.22l14.65,10s14-22.06,9.95-27.9C235.88,212.74,232.33,211.58,224.58,213.55Z"
          fill="#fff"
          opacity="0.5"
        ></path>
        <path
          d="M224.1,221.5l11.37,2.63,1.09,11.13a128.36,128.36,0,0,1-6.37,11.47l-8.89-6C221.69,235.58,222.65,225,224.1,221.5Z"
          opacity="0.2"
        ></path>
        <path
          d="M224.58,213.55s-6.15,2.16,6.16,77.82H283.1c-.88-21.31-.9-34.46,9.28-78.19a154,154,0,0,0-22.23-2.93c-8.81-.43-16-.71-23.78,0C236.16,211.18,224.58,213.55,224.58,213.55Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M224.58,213.55s-6.15,2.16,6.16,77.82H283.1c-.88-21.31-.9-34.46,9.28-78.19a154,154,0,0,0-22.23-2.93c-8.81-.43-16-.71-23.78,0C236.16,211.18,224.58,213.55,224.58,213.55Z"
          fill="#fff"
          opacity="0.5"
        ></path>
        <path
          d="M280.06,233.48l6.76,5.76c-1.44,7.65-2.41,14-3,19.69C282.53,253.25,279.31,237.83,280.06,233.48Z"
          opacity="0.2"
        ></path>
        <path
          d="M267.79,183.47c-1.5,7.65-3,21.67,2.36,26.78,0,0-5,11.46-18.25,11.46s-5.53-11.46-5.53-11.46c8.53-2,8.31-8.37,6.83-14.31Z"
          fill="#b55b52"
        ></path>
        <path
          d="M268.45,207.39c-1.26,2.55-5.32,8.38-16.66,10.46a1.26,1.26,0,0,1-.91-.18c-2.12-1.48-3.44-4.61-2.51-8.24a1.24,1.24,0,0,0-1.66-1.48,15.82,15.82,0,0,0-2.5,1.32,1.21,1.21,0,0,0-.41.46c-.83,1.56-4.19,9.2,5.95,13.91a1.52,1.52,0,0,0,.51.12c6,.12,16.49-1.06,23.12-13a1.26,1.26,0,0,0-.11-1.38,12.92,12.92,0,0,0-3.07-2.47A1.22,1.22,0,0,0,268.45,207.39Z"
          fill="#fafafa"
        ></path>
        <path
          d="M271.23,238.87l-13.5-.39a1,1,0,0,1-1-1.11l.13-1.67a1.16,1.16,0,0,1,1.17-1l13.5.39a1,1,0,0,1,1,1.12l-.13,1.66A1.16,1.16,0,0,1,271.23,238.87Z"
          fill="#fafafa"
        ></path>
        <path
          d="M261.84,188.56l-8.64,7.37a24.26,24.26,0,0,1,.74,4.22c3.25-.47,7.73-4.05,8.08-7.46A11.44,11.44,0,0,0,261.84,188.56Z"
          opacity="0.2"
        ></path>
        <path
          d="M277.87,169c-2.41,12.57-3.16,17.94-10.4,23.61-10.89,8.54-25.8,2.82-27.18-10.21-1.25-11.73,2.9-30.31,16-33.75A17.45,17.45,0,0,1,277.87,169Z"
          fill="#b55b52"
        ></path>
        <path
          d="M270.72,159.7C269.16,167.57,268.79,178,276,178s12.86-12.27,6.64-21.88S271.63,155.06,270.72,159.7Z"
          fill="#263238"
        ></path>
        <path
          d="M283.18,160.67c0,8.56-10.78,4.33-15.55.64s-5-3.29-13.84-3.3-10.61-.62-13.22-4.84-2.37-7,3.51-6.11.94-5.11,5.32-8.44,9.57-2.49,15.56,5.11S283.18,145.84,283.18,160.67Z"
          fill="#263238"
        ></path>
        <ellipse
          cx="275.47"
          cy="178.85"
          rx="7.3"
          ry="4.73"
          transform="translate(26.35 392.88) rotate(-73.66)"
          fill="#fff"
        ></ellipse>
        <path
          d="M260,189.86a17.1,17.1,0,0,1-6.75-1.39.37.37,0,0,1-.2-.49.39.39,0,0,1,.5-.2,16.35,16.35,0,0,0,12.73.07A15.91,15.91,0,0,0,275,179.1c3-8.59,6.06-19.31,2.52-26a10.29,10.29,0,0,0-6.9-5.13.38.38,0,0,1-.27-.46.38.38,0,0,1,.46-.27,11.05,11.05,0,0,1,7.37,5.5c3.68,6.91.61,17.84-2.48,26.59a16.69,16.69,0,0,1-9.17,9.18A17.3,17.3,0,0,1,260,189.86Z"
          fill="#fff"
        ></path>
        <path
          d="M279.65,180.22c-.89,3-2.93,5.11-4.56,4.64s-2.23-3.33-1.34-6.37,2.93-5.12,4.56-4.64S280.54,177.18,279.65,180.22Z"
          fill="#D9D9D9"
          opacity="0.6"
        ></path>
        <rect
          x="248.81"
          y="186.4"
          width="6.53"
          height="2.77"
          rx="1.27"
          transform="translate(40.13 -44.34) rotate(10.92)"
          fill="#fff"
        ></rect>
        <path
          d="M249.17,170.05s-2.32,4.21-4.52,6.1c1.19,1.45,3.59,1.17,3.59,1.17Z"
          fill="#a02724"
        ></path>
        <path
          d="M251.38,182.7l.09,0a9,9,0,0,0,6.3-1.92.2.2,0,0,0,0-.28.19.19,0,0,0-.27,0,8.67,8.67,0,0,1-6,1.83.19.19,0,0,0-.22.17A.2.2,0,0,0,251.38,182.7Z"
          fill="#263238"
        ></path>
        <path
          d="M262.82,165.1a.38.38,0,0,0,.37,0,.4.4,0,0,0,.12-.54,5.87,5.87,0,0,0-4.55-2.84.4.4,0,0,0-.4.39.39.39,0,0,0,.38.39,5.11,5.11,0,0,1,3.91,2.48A.39.39,0,0,0,262.82,165.1Z"
          fill="#263238"
        ></path>
        <path
          d="M257.09,170.22c-.17,1-.86,1.77-1.54,1.66s-1.07-1-.9-2.08.87-1.77,1.54-1.66S257.27,169.19,257.09,170.22Z"
          fill="#263238"
        ></path>
        <path
          d="M245.43,168.24c-.17,1-.86,1.77-1.54,1.66s-1.07-1.05-.9-2.08.87-1.78,1.54-1.66S245.61,167.2,245.43,168.24Z"
          fill="#263238"
        ></path>
        <path
          d="M244.82,166.27l-2.36-1.14S243.4,167.28,244.82,166.27Z"
          fill="#263238"
        ></path>
        <path
          d="M256.48,168.25l-2.36-1.13S255.06,169.27,256.48,168.25Z"
          fill="#263238"
        ></path>
        <path
          d="M241.47,161.27a.39.39,0,0,0,.4,0,5,5,0,0,1,4.52-1,.39.39,0,0,0,.26-.74,5.8,5.8,0,0,0-5.25,1.08.37.37,0,0,0-.07.54A.31.31,0,0,0,241.47,161.27Z"
          fill="#263238"
        ></path>
        <path
          d="M228.75,304.12s-.14-7.16,2-12.75H283.1s4.71,7.6,5.93,12.75Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M283.89,288.3l.41,4.68c.19.37-.24.74-.85.74h-53c-.47,0-.86-.23-.89-.53l-.48-4.69c0-.32.38-.6.9-.6h53A1,1,0,0,1,283.89,288.3Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M283.89,288.3l.41,4.68c.19.37-.24.74-.85.74h-53c-.47,0-.86-.23-.89-.53l-.48-4.69c0-.32.38-.6.9-.6h53A1,1,0,0,1,283.89,288.3Z"
          fill="#fafafa"
          opacity="0.3"
        ></path>
        <path
          d="M276.76,294.22h1.42c.29,0,.5-.14.48-.32l-.66-6.09c0-.18-.27-.32-.55-.32H276c-.29,0-.5.14-.48.32l.66,6.09C276.23,294.08,276.48,294.22,276.76,294.22Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M234.47,294.22h1.42c.28,0,.5-.14.48-.32l-.67-6.09c0-.18-.26-.32-.55-.32h-1.42c-.28,0-.5.14-.48.32l.67,6.09C233.94,294.08,234.19,294.22,234.47,294.22Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M255.62,294.22H257c.28,0,.5-.14.48-.32l-.67-6.09c0-.18-.27-.32-.55-.32h-1.42c-.28,0-.5.14-.48.32l.67,6.09C255.09,294.08,255.33,294.22,255.62,294.22Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M296.86,228q3.45,11.2,6.63,22.52l3.19,11.26,1.59,5.6.4,1.38.2.69.05.16v0s0,0,0,0l-.16-.25a4,4,0,0,0-.92-.94,5,5,0,0,0-2.68-.95,4.49,4.49,0,0,0-2.12.42c-.72.39-.65.48-.59.41a3.32,3.32,0,0,0,.46-.49,18.11,18.11,0,0,0,1.3-1.85c.88-1.44,1.75-3.06,2.57-4.72,1.7-3.32,3.23-6.84,4.78-10.38s3-7.12,4.5-10.73,2.93-7.24,4.41-10.82l6.39,1.9c-1.66,7.8-3.6,15.46-6,23.09-1.18,3.83-2.49,7.62-4,11.46-.78,1.92-1.61,3.84-2.64,5.83a29.66,29.66,0,0,1-1.84,3.12,14.75,14.75,0,0,1-1.4,1.78,9.31,9.31,0,0,1-2.9,2.21,7.41,7.41,0,0,1-3.54.74,7.29,7.29,0,0,1-4-1.47,8.25,8.25,0,0,1-1.89-2,10.87,10.87,0,0,1-.85-1.55c-.09-.22-.19-.45-.26-.65l-.19-.49-.26-.71-.52-1.41c-.68-1.87-1.37-3.74-2-5.61-1.28-3.74-2.56-7.47-3.76-11.22q-3.68-11.24-6.86-22.59Z"
          fill="#b65b52"
        ></path>
        <path
          d="M292.38,213.18c5.25,1.59,13.28,22.18,13.28,22.18l-20.35,14.55s-9.15-17.6-6.95-24.38C280.66,218.47,286.4,211.36,292.38,213.18Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M292.38,213.18c5.25,1.59,13.28,22.18,13.28,22.18l-20.35,14.55s-9.15-17.6-6.95-24.38C280.66,218.47,286.4,211.36,292.38,213.18Z"
          fill="#fff"
          opacity="0.5"
        ></path>
        <path
          d="M320.7,232.05l-2.79-12.3,11.6,6.12s.14,8.28-4.54,10.89Z"
          fill="#b55b52"
        ></path>
        <polygon
          points="322.77 209.99 330.17 215.24 329.51 225.87 317.91 219.75 322.77 209.99"
          fill="#b55b52"
        ></polygon>
      </g>
      <g id="freepik--Chat--inject-266">
        <path
          d="M115,148.09h81a11.18,11.18,0,0,1,11.26,12L205,196.75a13,13,0,0,1-12.74,12h-9.38l3.83,12.59-18.32-12.59H111.29a11.18,11.18,0,0,1-11.26-12l2.24-36.66A13,13,0,0,1,115,148.09Z"
          fill="#D9D9D9"
        ></path>
        <path
          d="M188.58,167.29h-68.5A2.1,2.1,0,0,1,118,165h0a2.44,2.44,0,0,1,2.39-2.26h68.51A2.11,2.11,0,0,1,191,165h0A2.43,2.43,0,0,1,188.58,167.29Z"
          fill="#fff"
        ></path>
        <path
          d="M148,180.68H119.26a2.11,2.11,0,0,1-2.12-2.26h0a2.44,2.44,0,0,1,2.4-2.26h28.77a2.11,2.11,0,0,1,2.13,2.26h0A2.45,2.45,0,0,1,148,180.68Z"
          fill="#fff"
        ></path>
        <path
          d="M172.63,194.07H118.44a2.11,2.11,0,0,1-2.12-2.26h0a2.43,2.43,0,0,1,2.4-2.26h54.19a2.1,2.1,0,0,1,2.12,2.26h0A2.44,2.44,0,0,1,172.63,194.07Z"
          fill="#fff"
        ></path>
        <path
          d="M169.11,180.68H157.78a2.11,2.11,0,0,1-2.12-2.26h0a2.45,2.45,0,0,1,2.4-2.26h11.32a2.1,2.1,0,0,1,2.12,2.26h0A2.44,2.44,0,0,1,169.11,180.68Z"
          fill="#fff"
        ></path>
        <path
          d="M227.79,82.19H183.63a10.09,10.09,0,0,0-9.91,9.33L172,120.06a8.71,8.71,0,0,0,8.77,9.33H188l-4.18,9.8,15.46-9.8H224.9a10.09,10.09,0,0,0,9.91-9.33l1.75-28.54A8.71,8.71,0,0,0,227.79,82.19Z"
          fill="#D9D9D9"
        ></path>
        <g opacity="0.5">
          <path
            d="M227.79,82.19H183.63a10.09,10.09,0,0,0-9.91,9.33L172,120.06a8.71,8.71,0,0,0,8.77,9.33H188l-4.18,9.8,15.46-9.8H224.9a10.09,10.09,0,0,0,9.91-9.33l1.75-28.54A8.71,8.71,0,0,0,227.79,82.19Z"
            fill="#fafafa"
          ></path>
        </g>
        <path
          d="M206.5,110.46a2.89,2.89,0,0,1-2.8,2.21h-2.61c0-.74.06-1.19.07-1.35a9.38,9.38,0,0,1,1.08-4.13,13.81,13.81,0,0,1,3.54-3.64,27.19,27.19,0,0,0,3.14-2.66,3.76,3.76,0,0,0,.88-2.18,3.48,3.48,0,0,0-1.15-2.85,4.94,4.94,0,0,0-3.5-1.18,5.68,5.68,0,0,0-3.7,1.24,5.89,5.89,0,0,0-1.5,1.87,3,3,0,0,1-2.9,1.62h0a2.54,2.54,0,0,1-1.84-3.87,10.17,10.17,0,0,1,2.29-2.66,11.51,11.51,0,0,1,7.67-2.53c3.27,0,5.81.85,7.64,2.56a7.31,7.31,0,0,1,2.53,6,7,7,0,0,1-1.28,3.56,25.7,25.7,0,0,1-4.83,4.58,9.35,9.35,0,0,0-2.38,2.41A3.81,3.81,0,0,0,206.5,110.46Zm-5.71,7.18,0-.32a2.89,2.89,0,0,1,2.85-2.68H204a2.5,2.5,0,0,1,2.53,2.68l0,.32a2.9,2.9,0,0,1-2.85,2.69h-.32A2.5,2.5,0,0,1,200.79,117.64Z"
          fill="#fff"
        ></path>
      </g>
    </svg>
  );
};

export default ActiveSupportSVG;
