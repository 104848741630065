import SignupSvg from "../../../Assets/Svg/login/signup";
import UndoSvg from "../../../Assets/Svg/undo";

const Signup = () => {
  return (
    <div className="max-w-[400px] mt-20 mx-auto p-6">
      <div className="flex w-full justify-between opacity-50">
        <UndoSvg />
        <h1 className="text-lg font-semibold">ثبت نام</h1>
      </div>{" "}
      <div className="flex justify-center mt-4">
        {" "}
        <SignupSvg />
        <div>
          هر گونه پیشنهاد ساخت حساب کاربری برای اشخاص غیر، کلاهبرداری محسوب
          می‌شود و تمام عواقب حقوقی و قضایی آن بر عهده شما خواهد بود.
        </div>
      </div>
      <div>
        <h1 className="text-[12px] font-medium opacity-50 text-right mt-3">
          نام و نام خانوادگی{" "}
        </h1>
        <input
          className="bg-[#959595]/10 rounded-[5px] pl-3 w-full h-[40px] focus:text-[18px] text-[14px] font-medium text-black/50 mt-1 text-right pr-3"
          placeholder="به همراه پسوند و پیشوند"
        />
        <h1 className="text-[12px] font-medium opacity-50 text-right mt-3">
          کد ملی{" "}
        </h1>
        <input
          className="bg-[#959595]/10 rounded-[5px] pl-3 w-full h-[40px] text-[18px] font-medium text-black/50 mt-1"
          placeholder="012345678"
        />
        <h1 className="text-[12px] font-medium opacity-50 text-right mt-3">
          ایمیل{" "}
        </h1>
        <input
          className="bg-[#959595]/10 rounded-[5px] pl-3 w-full h-[40px] text-[18px] font-medium text-black/50 mt-1"
          placeholder="example@gmail.com"
        />
        <h1 className="text-[12px] font-medium opacity-50 text-right mt-3">
          تاریخ تولد{" "}
        </h1>
        <div className="flex gap-x-4 ">
          {" "}
          <input
            className="bg-[#959595]/10 rounded-[5px] w-full h-[40px] text-[18px] font-medium text-black/50 mt-1 text-center"
            placeholder="1379"
          />
          <input
            className="bg-[#959595]/10 rounded-[5px] w-full h-[40px] text-[18px] font-medium text-black/50 mt-1 text-center"
            placeholder="04"
          />
          <input
            className="bg-[#959595]/10 rounded-[5px] w-full h-[40px] text-[18px] font-medium text-black/50 mt-1 text-center"
            placeholder="18"
          />
        </div>

        <button className="bg-black rounded-[15px] text-white text-center h-[70px] w-full mt-10 mb-16 text-[18px]">
          ثبت نام
        </button>
      </div>
    </div>
  );
};

export default Signup;
