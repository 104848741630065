import UdnoSvg from "../../Assets/Svg/undo";
import Login1Svg from "../../Assets/Svg/login/login-signup";

const LoginSignup = () => {
  return (
    <div className="max-w-[400px] mt-20 mx-auto p-6">
      <div className="flex w-full justify-between opacity-50">
        <UdnoSvg />
        <h1 className="text-lg font-semibold">ورود/ ثبت نام</h1>
      </div>{" "}
      <div className="flex justify-center mt-10">
        {" "}
        <Login1Svg />
      </div>
      <div>
        <h1 className="text-[12px] font-medium opacity-50 text-right mt-8">
          شماره تلفن همراه
        </h1>
        <input
          className="bg-[#959595]/10 rounded-[5px] pl-3 w-full h-[40px] text-[18px] font-medium text-black/50 mt-1"
          placeholder="09123456789"
        />

        <button className="bg-black rounded-[15px] text-white text-center h-[70px] w-full mt-20 text-[18px]">
          ورود
        </button>
      </div>
    </div>
  );
};

export default LoginSignup;
