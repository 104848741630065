import { NavLink } from "react-router-dom";
import UdnoSvg from "../../Assets/Svg/undo";

const NewTicket = () => {
  return (
    <div className="max-w-[390px] mx-auto">
      <div className="flex justify-between mt-5">
        <NavLink to={"/support"}>
          <UdnoSvg />
        </NavLink>
        <p className="font-semibold text-[15px] mt-2 mr-2 text-black/50">
          ارسال تیکت جدید
        </p>
      </div>
      {/* Form */}
      <p className="text-right text-[12px] mr-3 mt-4 mb-2">موضوع</p>
      <div className="w-full flex justify-center">
        <input
          placeholder="موضوع و یا بخش مربوطه را درج کنید"
          className="w-[375px] h-[50px] border-2 focus:border-black border-black/50 transition duration-500 text-right pr-3 rounded-[10px] text-[12px]"
        />
      </div>
      <p className="text-right text-[12px] mr-3 mt-4 mb-2">توضیحات</p>
      <div className="w-full flex justify-center ">
        <input
          placeholder="مشکل و یا سوال خود را با درج جزئیات در طی یک پیام اعلام بفرمایید"
          className="text-right  w-[375px] h-[300px] border-2 focus:border-black border-black/50 transition duration-500  pr-3 pt-3 rounded-[10px] text-[12px]"
        />
      </div>
      <p className="text-right text-[12px] mr-3 mt-4 mb-2">فایل ضمیمه</p>
      <div className="w-full flex justify-center">
        <input
          placeholder="فایلی انتخاب نکرده اید..."
          className="w-[375px] h-[50px] border-2 focus:border-black border-black/50 transition duration-500 text-right pr-3 rounded-[10px] text-[12px]"
        />
      </div>
      <div className="w-full flex justify-center">
        <NavLink to={"/support"}>
          <button className="w-[375px] h-[55px] rounded-[15px] text-[16px] text-center bg-black text-white mt-8">
            ثبت درخواست
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default NewTicket;
