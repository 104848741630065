const TransferSvg = () => {
  return (
    <svg
      fill="#FFFFFF"
      height="800px"
      width="800px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 489.9 489.9"
      space="preserve"
      className="w-6 h-6"
    >
      <g>
        <g>
          <path
            d="M176.9,0.4h-43.8c-52.9,0-96,43.1-96,96v61.9l-16.2-16.2c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l37.1,37.1
          c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l37.1-37.1c4.8-4.8,4.8-12.5,0-17.3s-12.5-4.8-17.3,0l-16.2,16.2V96.4
          c0-39.4,32.1-71.5,71.5-71.5h43.7c6.8,0,12.3-5.5,12.3-12.2S183.7,0.4,176.9,0.4z"
          />
          <path
            d="M251,13.6v214.3c0,6.8,5.5,12.3,12.3,12.3h214.3c6.8,0,12.3-5.5,12.3-12.3V13.6c0-6.8-5.5-12.3-12.3-12.3H263.2
          C256.5,1.4,251,6.8,251,13.6z M275.5,25.9h189.8v189.8H275.5V25.9z"
          />
          <path
            d="M356.7,464.9h-43.8c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h43.8c52.9,0,96-43.1,96-96v-61.9l16.2,16.2
          c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-37.1-37.1c-4.6-4.6-12.7-4.6-17.3,0l-37.1,37.1
          c-4.8,4.8-4.8,12.5,0,17.3s12.5,4.8,17.3,0l16.2-16.2v61.9C428.2,432.8,396.1,464.9,356.7,464.9z"
          />
          <path
            d="M226.6,249.7H12.3c-6.8,0-12.2,5.5-12.2,12.3v214.3c0,6.8,5.5,12.3,12.2,12.3h214.3c6.8,0,12.3-5.5,12.3-12.3V261.9
          C238.8,255.2,233.3,249.7,226.6,249.7z M214.3,463.9H24.5V274.2h189.8V463.9z"
          />
        </g>
      </g>
    </svg>
  );
};

export default TransferSvg;
