import { NavLink } from "react-router-dom";
import UdnoSvg from "../../../../Assets/Svg/undo";

import TimeSVG from "../../../../Assets/Svg/time";
const Statics = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[400px] flex flex-col mt-6 mr-6 w-full px-3">
        <div className="w-full items-end flex justify-between ">
          <div className="flex w-full items-center ml-auto cursor-pointer justify-between">
            {" "}
            <NavLink to={"/profile"}>
              {" "}
              <UdnoSvg />
            </NavLink>
            <div className="flex flex-row mr-[-10px]">
              {" "}
              <div className="">
                <p className="text-right text-[14px] font-medium mt-1">آمار</p>
                <p className="text-[12px] opacity-50  text-right">
                  میزان فعالیت شما{" "}
                </p>
              </div>{" "}
              <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                <TimeSVG />
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="flex flex-col gap-y-10 mt-10 ml-3">
          <div>
            <h1 className="text-[18px] font-semibold text-center ">
              مجموع خرید
            </h1>
            <div className="flex justify-center text-center mr-4 mt-2">
              {" "}
              <p className="text-[8px] pt-2 font-medium mr-1 text-black/50 ">
                تومان
              </p>{" "}
              <p className="font-medium text-[18px] text-black/50">
                2,000,000,000
              </p>
            </div>{" "}
          </div>
          <div>
            <h1 className="text-[18px] font-semibold text-center ">
              مجموع فروش
            </h1>
            <div className="flex justify-center text-center mr-4 mt-2">
              {" "}
              <p className="text-[8px] pt-2 font-medium mr-1 text-black/50 ">
                تومان
              </p>{" "}
              <p className="font-medium text-[18px] text-black/50">
                2,000,000,000
              </p>
            </div>{" "}
          </div>
          <div>
            <h1 className="text-[18px] font-semibold text-center ">
              تعداد تراکنش
            </h1>
            <div className="flex justify-center text-center mr-4 mt-2">
              {" "}
              <p className="text-[8px] pt-2 font-medium mr-1 text-black/50 ">
                تومان{" "}
              </p>{" "}
              <p className="font-medium text-[18px] text-black/50">
                2,000,000,000
              </p>
            </div>{" "}
          </div>
          <div>
            <h1 className="text-[18px] font-semibold text-center ">
              تاریخ عضویت
            </h1>
            <div className="flex justify-center text-xl text-black/50 text-center mt-2">
              1402/1/1{" "}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statics;
