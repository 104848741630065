import { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

export default function TradingViewWidget({ symbol }) {
  const onLoadScriptRef = useRef();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_f41d5") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          height: "450px",
          width: "98%",

          //   autosize: true,
          symbol:
            symbol === "USDT" ? "BINANCEUS:USDTUSD" : `KUCOIN:${symbol}USDT`,
          interval: "D",
          timezone: "Asia/Tehran",
          theme: `${"light"}`,
          style: "1",
          locale: "en",
          toolbar_bg: "#fffff",
          enable_publishing: false,
          allow_symbol_change: false,
          container_id: "tradingview_f41d5",
          show_popup_button: true,
          hide_volume: true,
        });
      }
    }
  }, [symbol]);

  return (
    <div>
      <div className="tradingview-widget-container">
        <div id="tradingview_f41d5" />
        <div className="tradingview-widget-copyright"></div>
      </div>
    </div>
  );
}
