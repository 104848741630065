import { NavLink } from "react-router-dom";
import UdnoSvg from "../../../../Assets/Svg/undo";
import CardTransfer from "../../../../Assets/Svg/card-transfer";
import PlusSvg from "../../../../Assets/Svg/plus";

const BankCards = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[400px] flex flex-col mt-6 mr-6 w-full px-3">
        {/* head */}
        <div className="w-full items-end flex justify-between ">
          <div className="flex w-full items-center ml-auto cursor-pointer justify-between">
            {" "}
            <NavLink to={"/profile"}>
              {" "}
              <UdnoSvg />
            </NavLink>
            <div className="flex flex-row mr-[-10px]">
              {" "}
              <div className="">
                <p className="text-right text-[14px] font-medium mt-1">
                  کارت های بانکی{" "}
                </p>
                <p className="text-[12px] opacity-50 text-right">
                  حساب های ثبت شده{" "}
                </p>
              </div>{" "}
              <div className="ml-2 w-[50px] h-[50px] bg-black flex items-center justify-center rounded-full overflow-hidden">
                <CardTransfer />
              </div>
            </div>
          </div>
        </div>
        {/* card */}
        {/* map here */}
        <div className="flex justify-center mt-10 rounded-[15px] ml-3 w-full">
          <div className="w-[350px] h-[50px] bg-[#959595]/10 flex justify-center pt-3 opacity-50">
            <PlusSvg />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCards;
