const SignupSvg = () => {
  return (
    <svg
      width="301"
      height="300"
      viewBox="0 0 301 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M242.302 26.7803C242.302 26.7803 209.758 2.73827 168.16 15.9803C126.562 29.2223 109.636 64.9883 86.0745 90.1463C73.7265 103.346 55.2705 108.812 40.5225 119.192C-6.64353 152.39 1.55247 221.792 38.7225 246.392C70.9185 267.71 110.722 268.274 162.19 249.11C213.658 229.946 250.552 218.192 281.836 168.62C313.12 119.048 282.436 59.6003 242.302 26.7803Z"
        fill="#EDEDED"
      />
      <path
        opacity="0.8"
        d="M242.302 26.7803C242.302 26.7803 209.758 2.73827 168.16 15.9803C126.562 29.2223 109.636 64.9883 86.0745 90.1463C73.7265 103.346 55.2705 108.812 40.5225 119.192C-6.64353 152.39 1.55247 221.792 38.7225 246.392C70.9185 267.71 110.722 268.274 162.19 249.11C213.658 229.946 250.552 218.192 281.836 168.62C313.12 119.048 282.436 59.6003 242.302 26.7803Z"
        fill="white"
      />
      <path
        d="M250.924 18.6318H50.248V175.478H250.924V18.6318Z"
        fill="#263238"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M258.04 12.4941H56.7578V169.712H258.04V12.4941Z"
        fill="#EDEDED"
      />
      <path
        d="M258.04 12.4941H56.7578V169.712H258.04V12.4941Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.836 34.7725H62.2363V61.7725H104.836V34.7725Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.9355 46.4785H91.9355"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M69.4355 50.9785H99.1355"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M104.836 66.8721H62.2363V93.8721H104.836V66.8721Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.6699 73.2803H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 76.8564H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 80.4326H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 84.0078H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 87.584H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 73.2803H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 76.8564H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 80.4326H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 84.0078H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 87.584H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M104.836 98.9717H62.2363V125.972H104.836V98.9717Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.6699 105.38H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 108.956H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 112.532H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 116.108H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 119.685H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 105.38H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 108.956H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 112.532H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 116.108H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 119.685H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M104.836 131.072H62.2363V158.072H104.836V131.072Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.6699 137.48H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 141.056H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 144.632H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 148.208H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6699 151.784H81.6099"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 137.48H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 141.056H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 144.632H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 148.208H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M85.168 151.784H100.108"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M258.034 12.4941H110.836V169.712H258.034V12.4941Z"
        fill="#B8B8B8"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M248.235 42.2783H115.035V132.878H248.235V42.2783Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M145.984 78.2422H219.088"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M136.049 86.1865H229.025"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M258.034 12.4941H252.832V169.712H258.034V12.4941Z"
        fill="#DBDBDB"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M258.034 61.2021H252.832V103.682H258.034V61.2021Z"
        fill="#878787"
      />
      <path
        d="M258.04 12.4941H56.7578V22.7061H258.04V12.4941Z"
        fill="#EDEDED"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.402 14.2344H69.9941V19.9944H199.402V14.2344Z"
        fill="white"
      />
      <path
        d="M248.23 26.3184H238.24"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M248.23 29.3965H238.24"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M248.23 32.4678H238.24"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M260.236 233.942L262.666 226.994"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M260.41 215.181V241.065"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M256.426 228.608C256.426 228.608 252.826 224.678 249.226 225.332C245.626 225.986 243.982 230.252 243.982 232.874C243.982 235.496 247.582 244.016 247.582 244.016C247.582 244.016 250.204 241.07 256.108 236.816C262.012 232.562 262.33 228.278 256.426 228.608Z"
        fill="#EDEDED"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M256.427 228.608C253.336 230.359 250.832 232.982 249.227 236.15"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M262.307 223.994C262.307 223.994 265.307 220.76 268.253 221.306C271.199 221.852 272.567 225.368 272.561 227.528C272.555 229.688 269.561 236.708 269.561 236.708C267.427 234.497 265.082 232.499 262.559 230.744C257.705 227.222 257.441 223.712 262.307 223.994Z"
        fill="#EDEDED"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M262.307 223.994C264.853 225.439 266.914 227.606 268.229 230.222"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M259.889 219.699L264.407 213.093"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M259.132 217.784C259.132 217.784 259.06 213.392 256.474 211.826C253.888 210.26 250.558 211.988 249.124 213.626C247.69 215.264 245.236 222.452 245.236 222.452C245.236 222.452 248.464 222.062 254.434 222.662C260.404 223.262 262.948 220.808 259.132 217.784Z"
        fill="#EDEDED"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M259.132 217.784C256.269 217.17 253.288 217.417 250.564 218.492"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M263.015 211.892C263.015 211.892 263.069 208.658 264.971 207.506C266.873 206.354 269.333 207.626 270.371 208.814C271.409 210.002 273.221 215.324 273.221 215.324C270.965 215.156 268.698 215.208 266.453 215.48C262.073 215.918 260.237 214.118 263.015 211.892Z"
        fill="#EDEDED"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M263.014 211.892C265.121 211.44 267.315 211.622 269.32 212.414"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M271.45 238.845H248.164L250.522 263.085H269.092L271.45 238.845Z"
        fill="white"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M265.277 249.404C265.277 249.611 265.339 249.813 265.453 249.984C265.568 250.156 265.731 250.29 265.922 250.369C266.113 250.448 266.323 250.469 266.525 250.428C266.728 250.388 266.914 250.289 267.06 250.143C267.206 249.997 267.305 249.811 267.345 249.608C267.386 249.406 267.365 249.196 267.286 249.005C267.207 248.814 267.073 248.651 266.901 248.536C266.73 248.422 266.528 248.36 266.321 248.36C266.184 248.36 266.048 248.386 265.921 248.438C265.794 248.49 265.678 248.567 265.581 248.664C265.484 248.761 265.407 248.877 265.355 249.004C265.303 249.131 265.277 249.267 265.277 249.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M261.436 249.404C261.436 249.611 261.497 249.813 261.612 249.984C261.726 250.156 261.889 250.29 262.08 250.369C262.271 250.448 262.481 250.469 262.683 250.428C262.886 250.388 263.072 250.289 263.218 250.143C263.364 249.997 263.463 249.811 263.504 249.608C263.544 249.406 263.523 249.196 263.444 249.005C263.365 248.814 263.231 248.651 263.06 248.536C262.888 248.422 262.686 248.36 262.48 248.36C262.342 248.36 262.206 248.386 262.079 248.438C261.952 248.49 261.837 248.567 261.74 248.664C261.642 248.761 261.566 248.877 261.513 249.004C261.461 249.131 261.435 249.267 261.436 249.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M248.938 246.794H270.676L270.778 245.756H248.836L248.938 246.794Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M269.746 256.352L270.184 251.834H249.43L249.868 256.352H269.746Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M269.098 249.404C269.098 249.541 269.125 249.676 269.177 249.803C269.23 249.929 269.307 250.044 269.404 250.14C269.501 250.237 269.616 250.313 269.743 250.365C269.869 250.416 270.005 250.443 270.142 250.442H270.322L270.514 248.45C270.395 248.404 270.269 248.38 270.142 248.378C269.868 248.378 269.605 248.485 269.41 248.677C269.214 248.869 269.102 249.13 269.098 249.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M257.633 249.404C257.633 249.611 257.694 249.813 257.809 249.984C257.923 250.156 258.087 250.29 258.277 250.369C258.468 250.448 258.678 250.469 258.881 250.428C259.083 250.388 259.269 250.289 259.415 250.143C259.561 249.997 259.66 249.811 259.701 249.608C259.741 249.406 259.72 249.196 259.641 249.005C259.562 248.814 259.429 248.651 259.257 248.536C259.085 248.422 258.883 248.36 258.677 248.36C258.54 248.36 258.403 248.386 258.276 248.438C258.149 248.49 258.034 248.567 257.937 248.664C257.84 248.761 257.763 248.877 257.711 249.004C257.658 249.131 257.632 249.267 257.633 249.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M249.988 249.404C249.988 249.611 250.05 249.813 250.164 249.984C250.279 250.156 250.442 250.29 250.633 250.369C250.824 250.448 251.033 250.469 251.236 250.428C251.438 250.388 251.625 250.289 251.771 250.143C251.917 249.997 252.016 249.811 252.056 249.608C252.097 249.406 252.076 249.196 251.997 249.005C251.918 248.814 251.784 248.651 251.612 248.536C251.441 248.422 251.239 248.36 251.032 248.36C250.895 248.36 250.759 248.386 250.632 248.438C250.505 248.49 250.389 248.567 250.292 248.664C250.195 248.761 250.118 248.877 250.066 249.004C250.014 249.131 249.988 249.267 249.988 249.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M253.811 249.404C253.811 249.611 253.872 249.813 253.987 249.984C254.101 250.156 254.264 250.29 254.455 250.369C254.646 250.448 254.856 250.469 255.058 250.428C255.261 250.388 255.447 250.289 255.593 250.143C255.739 249.997 255.838 249.811 255.879 249.608C255.919 249.406 255.898 249.196 255.819 249.005C255.74 248.814 255.606 248.651 255.435 248.536C255.263 248.422 255.061 248.36 254.855 248.36C254.717 248.36 254.581 248.386 254.454 248.438C254.327 248.49 254.212 248.567 254.115 248.664C254.017 248.761 253.941 248.877 253.888 249.004C253.836 249.131 253.81 249.267 253.811 249.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M101.399 263.144C104.279 259.592 109.421 253.328 111.191 251.684C112.893 250.054 114.496 248.323 115.991 246.5C115.991 246.5 112.949 252.398 118.133 257.048C123.317 261.698 138.701 258.122 142.097 255.62C145.493 253.118 145.853 246.68 142.817 237.2C139.781 227.72 135.479 210.2 135.659 204.116C135.839 198.032 131.903 180.866 130.115 176.216C128.327 171.566 120.995 168.17 113.483 166.562C105.971 164.954 85.7628 173.354 85.7628 173.354L81.6528 174.962C81.6528 174.962 64.8528 184.262 60.3708 188.018C55.8888 191.774 58.0428 202.148 56.9748 212.162C55.9068 222.176 55.9008 233.804 55.9008 233.804C55.9008 233.804 45.5268 242.924 38.0148 253.292C34.4148 258.284 32.3208 261.326 31.1328 263.144H101.399Z"
        fill="#EDEDED"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M138.281 243.77C138.281 243.77 132.281 242.36 125.993 246.578C119.705 250.796 121.421 257.114 127.043 258.518C132.665 259.922 140.039 259.922 143.903 253.952C147.767 247.982 141.095 243.416 138.281 243.77Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M139.776 243.716C136.412 243.182 132.973 243.969 130.176 245.912C125.244 249.2 126.612 254.138 130.998 255.236C135.384 256.334 141.144 256.334 144.162 251.672C147.18 247.01 141.966 243.446 139.776 243.716Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M125.891 203.72C126.089 200.456 126.215 198.23 126.215 198.032"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M116.021 246.5C116.021 246.5 122.459 240.242 123.527 233.084C124.193 228.674 125.057 216.656 125.621 207.914"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M133.498 215.84C131.878 221.048 128.878 228.866 124.246 234.158"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M134.975 210.374C134.975 210.374 134.723 211.574 134.189 213.494"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M134.412 230.768C131.249 234.39 127.513 237.469 123.354 239.882"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M137.478 226.826C136.822 227.814 136.111 228.763 135.348 229.67"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M143.741 242.03C137.774 240.222 131.388 240.345 125.495 242.384C115.481 245.786 116.555 253.472 117.449 254.726"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M116.598 117.404C116.598 117.404 116.598 115.298 113.7 110.804C110.802 106.31 96.0477 108.17 91.0437 113.174C86.0397 118.178 93.1497 124.502 89.9877 128.174C86.8257 131.846 76.2897 130.016 72.8637 136.604C69.4377 143.192 78.1317 146.348 79.9797 148.718C81.8277 151.088 76.8177 151.88 77.3457 154.25C77.8737 156.62 86.0337 156.884 86.0337 156.884C86.0337 156.884 80.7657 158.468 83.3997 160.574C87.0295 163.319 91.5007 164.717 96.0477 164.528C101.316 164.264 99.9957 153.986 99.4737 146.348C98.9517 138.71 105.792 130.28 113.7 131.066C121.608 131.852 130.032 140.816 128.7 144.77C127.368 148.724 130.806 143.978 137.922 139.496C145.038 135.014 141.876 125.798 140.322 123.956C138.768 122.114 133.476 121.586 132.42 118.16C131.364 114.734 128.994 111.314 124.518 111.314C120.042 111.314 121.074 117.14 116.598 117.404Z"
        fill="#263238"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M94.0361 164.768C94.0361 164.768 90.6401 165.662 89.2361 167.456C87.8321 169.25 82.0361 172.478 81.6821 174.962C81.3281 177.446 83.4821 190.346 87.7601 195.53C92.0381 200.714 94.1981 201.074 94.1981 201.074C94.1981 201.074 95.4521 196.07 96.3461 195.71C97.2401 195.35 105.466 200.51 105.466 200.51C105.466 200.51 106.894 192.11 107.794 192.11C108.694 192.11 111.544 194.972 111.544 194.972C111.544 194.972 112.618 187.814 111.904 182.27C111.19 176.726 106.54 169.934 103.144 167.606C99.7481 165.278 97.0361 163.16 94.0361 164.768Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M95.7836 157.976L89.1836 173.78C89.1836 173.78 90.8816 181.142 93.1136 185.876C96.1556 192.314 103.674 195.38 103.674 195.38C103.625 191.646 104.436 187.95 106.044 184.58C107.545 181.535 109.308 178.626 111.312 175.886C111.312 175.886 99.4736 164.3 95.7836 157.976Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M97.4863 163.97C97.4863 163.97 100.516 181.292 104.848 186.704L108.958 178.904L98.1583 162.014L97.4863 163.97Z"
        fill="#263238"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M94.9121 215.384C94.9121 215.384 105.466 187.838 106.912 186.23C108.358 184.622 111.562 181.76 112.996 179.792C114.43 177.824 118.36 172.82 119.794 172.82C121.228 172.82 124.594 174.068 126.232 174.428C127.87 174.788 128.914 175.322 129.088 178.028C129.262 180.734 126.946 180.89 126.946 180.89L126.052 179.816C126.052 179.816 119.614 189.476 117.652 190.724C116.48 191.479 115.218 192.083 113.896 192.524L105.31 224.54L94.9121 215.384Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M132.137 135.512C132.137 135.512 134.639 137.6 132.971 144.68C131.303 151.76 130.889 155.306 130.889 159.476C130.889 163.646 129.641 169.478 126.089 173.228C122.537 176.978 118.175 182.396 112.967 182.186C104.717 181.856 100.673 170.516 97.9667 165.308C95.2607 160.1 94.6367 153.014 94.6367 150.098C94.6367 147.182 95.0507 140.306 98.8007 135.098C102.551 129.89 112.763 127.808 117.137 128.432C121.511 129.056 126.929 129.68 132.137 135.512Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M96.5079 154.268C96.5079 154.268 97.3419 151.352 101.092 149.684C104.842 148.016 107.134 145.1 105.892 142.388C104.65 139.676 105.292 136.988 110.692 135.512C116.092 134.036 117.568 139.472 122.776 138.638C127.984 137.804 129.442 135.722 132.148 136.97C134.854 138.218 133.606 141.77 133.606 141.77C133.606 141.77 136.942 135.728 130.486 128.852C124.03 121.976 108.4 119.474 97.5639 127.394C86.7279 135.314 92.9799 153.854 92.9799 153.854C92.9799 153.854 93.5919 154.682 96.5079 154.268Z"
        fill="#263238"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M122.555 157.808L121.931 169.892L117.551 168.224"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M119.237 174.278C119.237 174.278 115.073 174.878 113.195 171.986"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M114.623 157.682C114.431 158.882 113.765 159.758 113.141 159.656C112.517 159.554 112.169 158.504 112.361 157.31C112.553 156.116 113.219 155.234 113.843 155.336C114.467 155.438 114.815 156.488 114.623 157.682Z"
        fill="#263238"
      />
      <path
        d="M127.973 158.018C127.781 159.218 127.115 160.094 126.491 159.992C125.867 159.89 125.513 158.84 125.711 157.652C125.909 156.464 126.569 155.57 127.193 155.672C127.817 155.774 128.165 156.824 127.973 158.018Z"
        fill="#263238"
      />
      <path
        d="M109.301 152.24C109.301 152.24 113.783 148.334 117.395 151.37"
        stroke="#263238"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M125.057 151.37C125.057 151.37 128.093 148.772 130.697 151.226"
        stroke="#263238"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M120.287 154.844C120.287 154.844 113.927 153.83 104.087 155.444C104.087 155.444 103.223 162.092 107.849 164.984C112.475 167.876 116.957 165.128 118.109 163.538C120.437 160.37 120.287 154.844 120.287 154.844Z"
        stroke="#263238"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M134.885 153.932C134.885 153.932 130.337 152.966 123.305 154.484C123.305 154.484 122.705 160.844 125.993 163.604C129.281 166.364 132.509 163.742 133.337 162.224C134.969 159.218 134.885 153.932 134.885 153.932Z"
        stroke="#263238"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M120.287 154.844C120.287 154.844 120.971 152.972 123.287 154.484"
        stroke="#263238"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M94.4082 151.514L104.092 155.42"
        stroke="#263238"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M97.3424 155.306C97.3424 155.306 95.8844 146.768 91.9424 150.932C88.0004 155.096 91.5284 159.266 93.4004 161.132C95.2724 162.998 97.6004 161.132 97.6004 161.132"
        fill="white"
      />
      <path
        d="M97.3424 155.306C97.3424 155.306 95.8844 146.768 91.9424 150.932C88.0004 155.096 91.5284 159.266 93.4004 161.132C95.2724 162.998 97.6004 161.132 97.6004 161.132"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M81.7299 197.402C84.2973 202.166 86.0342 207.332 86.8659 212.678C88.1199 221.264 87.9399 222.878 87.9399 222.878L94.1979 215.372C94.1979 215.372 104.398 222.524 106 224.846C107.602 227.168 107.8 227.888 107.8 227.888C107.8 227.888 107.8 241.838 101.896 250.244C95.9919 258.65 93.1299 258.644 87.4119 258.644C81.6939 258.644 74.3559 253.274 70.4199 244.334C66.4839 235.394 63.2619 222.356 62.5479 221.078C61.8339 219.8 59.5059 212.678 59.5059 212.678"
        fill="#EDEDED"
      />
      <path
        d="M81.7299 197.402C84.2973 202.166 86.0342 207.332 86.8659 212.678C88.1199 221.264 87.9399 222.878 87.9399 222.878L94.1979 215.372C94.1979 215.372 104.398 222.524 106 224.846C107.602 227.168 107.8 227.888 107.8 227.888C107.8 227.888 107.8 241.838 101.896 250.244C95.9919 258.65 93.1299 258.644 87.4119 258.644C81.6939 258.644 74.3559 253.274 70.4199 244.334C66.4839 235.394 63.2619 222.356 62.5479 221.078C61.8339 219.8 59.5059 212.678 59.5059 212.678"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M79.3841 233.372C78.7301 234.14 78.1841 234.782 77.7461 235.232"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M87.9416 222.878C87.9416 222.878 84.5996 227.078 81.5996 230.708"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M88.8348 221.996C92.7245 223.119 96.4517 224.743 99.9228 226.826C106.001 230.426 106.523 234.338 108.323 232.91C110.123 231.482 111.539 227.006 109.751 224.51C107.963 222.014 102.419 216.11 98.4888 214.31C94.5588 212.51 92.9448 213.59 91.6908 216.278C90.4368 218.966 87.5808 220.388 88.8348 221.996Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M88.6367 236.636C88.6836 238.339 88.9416 240.03 89.4047 241.67"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M88.8348 221.996C88.8348 221.996 88.4088 227.996 88.4988 233.582"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M94.9121 225.038C94.9121 225.038 96.1661 232.016 97.6001 235.052"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M137.303 242.384C137.303 242.384 131.219 253.118 132.113 254.384C133.007 255.65 152.861 254.384 152.861 254.384L153.929 250.094C153.929 250.094 158.045 251.702 161.267 251.702C164.489 251.702 161.981 250.808 160.907 249.734C159.833 248.66 153.575 244.73 153.035 244.37C152.495 244.01 142.127 241.31 137.303 242.384Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M150.257 241.148C150.257 241.148 153.131 240.284 153.899 242.108C154.667 243.932 154.283 251.708 153.995 253.7C153.707 255.692 152.369 255.614 151.793 253.7C151.217 251.786 149.873 241.436 149.873 241.436L150.257 241.148Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M144.221 242.012C144.221 242.012 148.535 239.042 149.783 240.572C151.031 242.102 152.183 254.18 152.561 255.044C152.939 255.908 150.929 256.574 150.161 255.71C149.393 254.846 147.191 250.442 146.525 248.816C145.859 247.19 144.221 242.012 144.221 242.012Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M135.695 245.27C135.695 245.27 138.095 240.86 140.105 239.618C142.115 238.376 144.305 240.668 145.373 242.396C146.441 244.124 147.287 248.624 147.773 250.826C148.259 253.028 149.573 255.518 148.829 255.806C148.085 256.094 145.091 253.604 144.227 252.266C143.363 250.928 141.257 245.75 141.257 245.75"
        fill="white"
      />
      <path
        d="M135.695 245.27C135.695 245.27 138.095 240.86 140.105 239.618C142.115 238.376 144.305 240.668 145.373 242.396C146.441 244.124 147.287 248.624 147.773 250.826C148.259 253.028 149.573 255.518 148.829 255.806C148.085 256.094 145.091 253.604 144.227 252.266C143.363 250.928 141.257 245.75 141.257 245.75"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M139.636 253.604C139.636 253.604 136.186 250.25 135.034 249.194C133.882 248.138 132.256 247.664 131.11 248.912C129.964 250.16 129.382 251.69 131.11 253.316C132.838 254.942 136.942 257.078 139.636 257.078C142.33 257.078 141.838 255.734 141.166 254.972C140.712 254.457 140.198 253.998 139.636 253.604Z"
        fill="white"
        stroke="#263238"
        stroke-miterlimit="10"
      />
      <path
        d="M105.491 263.084H231.221V259.49H105.491V263.084Z"
        fill="#B8B8B8"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M105.491 263.084H149.627V259.49H105.491V263.084Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.068 259.478H227.704L232.42 205.184C232.485 204.587 232.422 203.983 232.236 203.413C232.049 202.843 231.744 202.318 231.339 201.875C230.934 201.432 230.44 201.08 229.889 200.842C229.338 200.605 228.742 200.487 228.142 200.498H155.872C154.598 200.522 153.377 201.012 152.439 201.875C151.502 202.739 150.913 203.916 150.784 205.184L146.068 259.478Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M147.635 259.478H229.271L233.987 205.184C234.052 204.587 233.989 203.983 233.802 203.413C233.616 202.843 233.31 202.318 232.905 201.875C232.501 201.432 232.007 201.08 231.455 200.842C230.904 200.605 230.309 200.487 229.709 200.498H157.439C156.165 200.522 154.943 201.012 154.006 201.875C153.068 202.739 152.48 203.916 152.351 205.184L147.635 259.478Z"
        fill="#B8B8B8"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M188.998 228.278C189.138 226.892 189.782 225.606 190.807 224.663C191.832 223.72 193.167 223.185 194.56 223.16C195.215 223.148 195.866 223.277 196.468 223.536C197.07 223.795 197.61 224.18 198.052 224.664C198.494 225.148 198.828 225.72 199.032 226.344C199.236 226.967 199.304 227.626 199.234 228.278C199.093 229.664 198.449 230.95 197.424 231.893C196.399 232.836 195.064 233.371 193.672 233.396C193.016 233.407 192.366 233.279 191.764 233.02C191.161 232.761 190.621 232.376 190.179 231.892C189.737 231.408 189.403 230.835 189.199 230.212C188.996 229.589 188.927 228.93 188.998 228.278Z"
        fill="#263238"
        stroke="#263238"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M293.254 263.084H12.0879"
        stroke="#263238"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default SignupSvg;
