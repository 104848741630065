import { useState } from "react";
import X from "../../../Assets/Svg/X";
import usdtSvg from "../../../Assets/CryptoLogos/USDT.png";
import CoinSelect from "../../../API/CoinsSelect.json";
import BTC from "../../../Assets/Icons/BTC.png";
import IRT from "../../../Assets/Icons/IRI.png";
import UdnoSvg from "../../../Assets/Svg/undo";

const TradeBox = () => {
  const [selectModal, setSelectModal] = useState(false);

  return (
    <div className="max-w-[500px] w-[99%] h-[310px] border rounded-[20px] mx-auto mt-6 shadow-2xl">
      <div className="relative bg-gray-50 shadow-2xl shadow-black pt-2 pb-4 rounded-[20px]">
        <div className="shadow-xl w-[38px] h-[38px] bg-[#f4f4f4]  border-4 border-white  flex items-center justify-center rounded-[10px] absolute ml-[43%] mt-[100px]">
          <div className="-rotate-90">
            <UdnoSvg />
          </div>
        </div>
        {/* First box */}
        <div className="w-[95%] h-[115px] bg-[#f4f4f4] mx-auto rounded-[20px] mt-2 flex ">
          {/* info */}
          <div className=" w-[230px] h-full pl-4 pt-1">
            <p className="text-[11px] text-black/50  pt-2 font-medium">
              پرداخت میکنید
            </p>
            <p className="text-[30px]">0.0001</p>
            <div className="flex gap-x-1 text-black/50 text-[12px] font-medium">
              <p>1 BTC</p>
              <span>=</span>
              <p>4,000,000,000</p> <span>تومان</span>
            </div>
          </div>
          {/* Select */}
          <div
            onClick={() => setSelectModal(true)}
            className=" flex items-center justify-around w-[100px] h-[32px] rounded-[20px] bg-white absolute top-[35px] right-[25px] cursor-pointer hover:bg-[#f4f4f4] border"
          >
            <img className="w-6 h-6" src={BTC} alt="Asset one" />
            <p className="font-medium">BTC</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </div>
        {/* Second box */}
        <div className="w-[95%] h-[115px] bg-[#f4f4f4] mx-auto rounded-[20px] mt-1">
          {/* info */}
          <div className=" w-[230px] h-full pl-4 pt-1">
            <p className="text-[11px] text-black/50  pt-2 font-medium">
              دریافت میکنید
            </p>
            <p className="text-[30px] mt-2">5,600,000</p>
            <div className="flex gap-x-1 text-black/50 text-[12px] font-medium ">
              <span>=</span>
              <p>112.3</p> <span>USDT</span>
            </div>
          </div>
          {/* Select */}
          <div className=" flex items-center justify-around w-[100px] h-[32px] rounded-[20px] bg-white absolute top-[160px] right-[25px]">
            <img className="w-6 h-6" src={IRT} alt="Asset two" />
            <p className="font-medium">IRT</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </div>{" "}
        <div className="flex items-center justify-center w-[95%] shadow-xl h-[55px] text-white font-medium text-center bg-black  mx-auto rounded-[15px] mt-4">
          <p className="">تبدیل کن</p>
        </div>
      </div>
      {/* select modal */}
      {selectModal && (
        <div className="absolute top-0 left-0 right-0 z-50 bg-black/30 h-full overflow-hidden flex justify-center ">
          <div
            className="w-full mt-100px bg-[#ffffff]  max-w-[500px] mx-auto absolute bottom-0 rounded-t-[20px] overflow-y-auto no-scrollbar"
            style={{ top: "200px" }}
          >
            {/* first line */}
            <div className="flex justify-between px-6 py-5  border-b sticky top-0 bg-white">
              {" "}
              <div
                onClick={() => setSelectModal(false)}
                className="cursor-pointer mt-1"
              >
                <X />
              </div>
              <p className="font-medium">یک ارز را انتخاب بفرمایید</p>
            </div>
            {/* coins list */}
            <div className="mt-3">
              {CoinSelect.currencies.map((currency, index) => (
                <div
                  key={index}
                  className="h-[60px] w-[315px] flex justify-end mx-auto cursor-pointer"
                >
                  <div className="text-right flex items-end justify-end pb-3">
                    <div className="font-semibold mr-1">
                      <p className="text-[15px]">{currency.name_fa}</p>
                      <p className="text-[12px] text-black/50">
                        {currency.symbol}
                      </p>
                    </div>{" "}
                    <img
                      className="w-[35px] h-[35px]"
                      src={currency.image}
                      alt={currency.symbol}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TradeBox;
