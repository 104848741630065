import AlmostEqual from "../../Assets/Svg/AlmostEqual";
import Assets from "../../API/Assets.json";

const Wallet = () => {
  return (
    <div className="mx-1">
      <div className="mt-20 mx-auto max-w-[310px] font-semibold text-[16px] flex justify-around">
        <p className="opacity-50 cursor-not-allowed">سرمایه گذاری(بزودی)</p>
        <p className="border-b-2 pb-1 border-black/30 cursor-pointer">کیف پول</p>
      </div>
      {/* card */}
      <div className="w-full max-w-[390px] mx-auto bg-black h-[180px] mt-4 rounded-t-[20px] text-white shadow-xl">
        <div className="flex justify-between mx-8 pt-7">
          <div>
            <div className="flex">
              <p className="text-[22px]">254,000,000</p>
              <p className="opacity-50 text-[14px] ml-1 mt-1">تومان</p>
            </div>{" "}
            <div className="flex">
              <div className="mt-1 mr-1">
                <AlmostEqual color="white" />
              </div>
              <p className="font-thin opacity-50">$</p>
              <p className="font-thin opacity-50">4,000</p>
            </div>{" "}
          </div>

          <div className="flex mt-1 opacity-50">
            <div className="mr-1 "> </div>
            <p className="text-[12px] mt-1">مجموع دارایی شما</p>
          </div>
        </div>
        {/* buttons */}
        <div className="flex justify-around mt-8 text-[12px] gap-2 mx-2">
          {" "}
          <div className="cursor-pointer text-center w-full h-[30px] rounded-[3px] bg-white text-black">
            <p className="mt-[5px] font-bold"> انتقال</p>
          </div>
          <div className="cursor-pointer text-center w-full h-[30px] rounded-[3px] bg-white text-black">
            <p className="mt-[5px] font-bold"> برداشت</p>
          </div>
          <div className="cursor-pointer text-center w-full h-[30px] rounded-[3px] bg-white text-black">
            <p className="mt-[5px] font-bold"> واریز</p>
          </div>
        </div>
      </div>
      {/* List */}
      <div className="max-w-[390px] h-[calc(100vh-380px)] overflow-y-auto no-scrollbar border mx-auto shadow-2xl rounded-b-[10px]">
        <ul className="divide-y divide-gray-200">
          {Assets.currencies.map((asset, index) => (
            <li key={index} className="p-4 hover:bg-gray-50 cursor-pointer">
              <div className="flex justify-between items-center">
                {/* balance */}
                <div>
                  <p className="text-[14px] font-medium inline">
                    {asset.toman_balance}
                  </p>
                  <span className="text-[10px] text-black/50 ml-1  align-middle">
                    تومان
                  </span>{" "}
                  <div className="flex text-black">
                    {" "}
                    <div className="mt-[2px] mr-1">
                      <AlmostEqual color="#000000" />
                    </div>{" "}
                    <p className="text-sm ">${asset.usd_balance}</p>
                  </div>
                </div>
                {/* symbol */}
                <div className="flex items-center">
                  <div>
                    <p className="font-semibold text-right">{asset.name_fa}</p>
                    <p className="text-sm text-gray-500  text-right">
                      {asset.symbol}
                    </p>
                  </div>
                  <img
                    src={asset.image}
                    alt={asset.symbol}
                    className="w-6 h-6 ml-2"
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Wallet;
