import React from "react";
import supportIcon from "../../../Assets/Icons/support.svg";
import { Link } from "react-router-dom";

const SupportBtn = () => {
  return (
    <Link to="/support">
      <div className="w-[80px] h-[38px] flex bg-[#F9F9F9] rounded-[50px] justify-around">
        <img
          src={supportIcon}
          className="w-[20px] h-[20px] my-auto opacity-50"
          alt="Support"
        />
        <p className="text-black/50 my-auto text-[13px] font-medium">
          پشتیبانی
        </p>
      </div>
    </Link>
  );
};

export default SupportBtn;
