import { useNavigate } from "react-router-dom";
import Svg404 from "../../Assets/Svg/Svg404";

const Page404 = () => {
  const navigate = useNavigate();

  const handleReturnClick = () => {
    navigate(-1);
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-[600px] text-[25px] text-black/50 text-center">
        <Svg404 />
        <h1>صفحه مورد نظر یافت نشد</h1>
        <div
          className="max-w-[345px] h-[55px] bg-black text-white rounded-[15px] mt-[100px] cursor-pointer"
          onClick={handleReturnClick} // Attach click handler
        >
          <p className="text-center pt-[10px]">بازگشت</p>
        </div>
      </div>
    </div>
  );
};

export default Page404;
